.board-card {
  padding: 20px 10px 25px 10px;
  margin-bottom: 12px;
  border-radius: 10px !important;
  border: 1px solid rgba(238, 238, 238, 0.93) !important;
  background-color: white;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
  width: 290px;
  position: relative !important;

  .flex-container {
    display: flex;
    flex-direction: row !important;
    justify-content: start;
    margin-bottom: 16px;

    .image-container {
      position: relative;
      width: 90px;
      height: 90px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }

      .profile-button {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 35px;
        height: 35px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .name {
        color: black;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px;
      }

      .handle {
        color: #55abeb;
        font-size: 12px;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: lowercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        margin-bottom: 9px;
      }

      .metrics-container {
        display: flex;
        justify-content: center;

        .metrics {
          text-align: center;
          flex: 1;
          width: 75px;
          height: auto;
          border-radius: 10px;
          border: 1px solid #f2f2f2;
          padding: 2px 0;

          .number {
            color: #152a4a;
            font-size: 12px;
            font-weight: 700;
          }

          .text {
            color: #496a9b;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .tags-container {
    margin-bottom: 14px;

    .title {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .tags {
      display: flex;
      overflow-x: scroll;
      gap: 8px;

      &::-webkit-scrollbar {
        display: none !important;
      }
      &::-webkit-scrollbar-thumb {
        display: none !important;
      }

      .add-tag-button {
        min-width: 73px;
        border: none;
        background-color: #94a4ff1a;
        color: #94a4ff;
        font-weight: 600;
        font-size: 10px;
        border-radius: 50px;
        padding: 3px 12px;
      }

      .tag-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 3px 8px;
        border-radius: 50px;

        .name {
          color: white;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          text-wrap: nowrap;
        }

        .remove-button {
          cursor: pointer;
          background-color: white;
          border-radius: 50%;
          border: none;
          padding: 0 !important;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .fees-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;
    padding-top: 10px;

    .text {
      color: #343434;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .dropdown-container {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .view-insights-button {
    position: absolute;
    right: 10px;
    top: 30px;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
