.creatorOnboardingFormPackagesStep {
  width: 100%;

  .packagesSelectContainer {
    .headerFlexContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .heading {
        font-size: 16px;
        font-weight: 700;
      }

      .defaultButton {
        display: none;
      }
    }

    .packagesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .addPackageButton {
        border: 3px dotted #94a4ff;
        border-radius: 10px;
        width: 200px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        justify-content: center;
        cursor: pointer;

        .label {
          font-size: 16px;
          font-weight: 700;
        }
      }

      .creatorPackageCard,
      .addPackageButton {
        flex-basis: calc(50% - 10px) !important;
      }
    }
  }
}

@media (max-width: 950px) {
  .headerFlexContainer {
    .defaultButton {
      display: flex !important;
    }
  }

  .addPackageButton {
    display: none !important;
  }
}

@media (max-width: 840px) {
  .packagesContainer {
    flex-direction: column !important;
    gap: 10px !important;
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 600px) {
  .headerFlexContainer {
    .heading {
      font-size: 14px !important;
    }
  }

  .creatorPackageCard {
    padding: 16px 20px !important;

    .packageDetails {
      .packageName {
        font-size: 16px !important;
      }

      .packagePrice {
        font-size: 14px !important;
      }
    }

    .packageActions {
      .actionIcon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
