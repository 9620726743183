.generic-select-input {
  .react-select__control {
    border: 1px solid #E2E8F0 !important;
    border-radius: 25px !important;
    box-shadow: none !important;
    background-color: white !important;
    padding: 0 8px;
  }

  .react-select__control--menu-is-open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .react-select__indicator {
    color: black !important;

    &:hover {
      color: inherit !important;
    }
  }

  .react-select__menu {
    z-index: 10 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #e2e8f0 !important;
    box-shadow: none !important;
  }

  .react-select__option {
    background-color: transparent !important;
    color: black !important;
    border-radius: 0 !important;
    display: flex;
    gap: 6px;

    &:hover {
      background-color: #f5f6fa !important;
    }
  }

  .react-select__menu-list {
    padding: 0 !important;
  }

  .react-select__multi-value__remove {
    background-color: inherit !important;
    color: inherit !important;
  }

  .react-select__indicator {
    padding: 4px;
  }

  .react-select__indicator-separator {
    display: none !important;
  }
}
