.notes-modal {
  .modal-container {
    width: 550px;
    position: relative;
    padding: 40px;

    .modal-title {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 5px;
      border: none;
      background: none;
    }

    .form-field-container {
      margin-bottom: 2rem;
    }
  }
}
