.brandOrderMessages {
  .attachmentsContainer {
    max-width: 315px;
    margin-left: 48px;
    border-radius: 10px;
    margin-bottom: 10px;

    .attachments {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      overflow: hidden;
    }

    .attachment {
      flex-basis: calc(50% - 2.5px);
      border-radius: 10px;
      height: 150px;
    }

    .collapseIndicator {
      width: 100%;
      color: #94a4ff;
      font-size: 12px;
      font-weight: 600;
      margin-top: 5px;
      cursor: pointer;
    }
  }
}
