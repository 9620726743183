.creatorImage {
  height: 240px;
  width: 170px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .defaultSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(:has(.image)) {
    display: none;
  }
}
