@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(.reset-password-page) {
  background-color: white !important;
}

.reset-password-page {
  overflow-x: hidden;
  font-family: "Open-Sans";

  .logo-container {
    width: auto;
    height: auto;
    margin: 2rem 0 0 4rem;

    .logo {
      width: auto;
      height: auto;
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .form {
      width: 500px;
      margin: 0 20px;

      .form-fields-container {
        .form-field-container {
          margin-bottom: 30px;
        }
      }

      .icon-container {
        width: auto;
        height: auto;
        text-align: center;
        margin-bottom: 48px;

        .icon {
          width: auto;
          height: auto;
        }
      }

      .primary-text {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
      }

      .secondary-text {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }

      .form-fields-container {
        margin-bottom: 3rem;
      }
    }
  }

  @media (max-width: 768px) {
    .logo-container {
      text-align: center !important;
      margin: 2rem 0 0 0;
    }
  }

  @media (max-width: 576px) {
    .icon-container {
      .icon {
        width: 135px !important;
      }
    }
  }
}
