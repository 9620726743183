.tabSwitchContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  align-self: flex-start;

  @media (max-width: 1035px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 40px;
    border-radius: 0;
  }

  .tabSwitch {
    border-radius: 50px;
    border: 1px solid #e2e8f0;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    padding: 10px 100px 10px 40px;

    @media (max-width: 1035px) {
      padding: 8px 28px;
    }
  }

  .tabSwitchActive {
    border: none;
    background-color: #f6f7fb;
  }
}
