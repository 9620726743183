.influencer-package-card {
  border: 1px solid #e2e8f0;
  padding: 15px;
  border-radius: 10px;

  .package-description-text {
    font-size: 14px !important;
    font-weight: 400;
    min-height: 45px;
  }
  .card-title {
    font-size: 15px;
    font-weight: 700;
  }
}

.package-action-icon {
  width: 35px;
  height: 32px;
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 8px;
  cursor: pointer;
}
.h150-px {
  height: 150px;
}
.view-more-link {
  cursor: pointer;
  color: #94a3ff;
}
.drawer-position-class {
  position: relative;
  height: 100vh;
  padding: 10px;

  .checkout-btn {
    position: absolute;
    bottom: 10px;
    left: 40px;
  }
}
.lock-add-to-cart-btn {
  background-color: #000000 !important;
  border-radius: 25px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  padding: 0.6rem 1rem !important;
  font-size: 13px !important;
  opacity: 0.6 !important;
  &:hover {
    background-color: #000000;
  }
}
