.warning-modal {
  .modal-container {
    width: 450px;
    position: relative;
    padding: 40px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 5px;
      border: none;
      background: none;
    }

    .icon-container {
      text-align: center;
      width: 100%;
      height: auto;
      margin-bottom: 40px;
    }

    .primary-text {
      text-align: center;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .secondary-text {
      text-align: center;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    .button-group {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
}
