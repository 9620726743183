.filter-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem;
  flex: 1;
  gap: 0.5rem;
  font-size: 16px !important;

  .flex-container {
    display: flex;
    justify-content: start !important;
    gap: 6px !important;

    .label {
      font-weight: 600;
    }

    .tooltip-icon {
      padding-right: 1rem;
    }

    .clear-button {
      color: #94a4ff;
      font-weight: 600;
      cursor: pointer;
      margin-left: auto;
    }
  }

  .react-select__control {
    border: none !important;
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    background-color: #f5f6fa !important;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__multi-value {
    border-radius: 25px !important;
    padding: 0 3px;
  }

  .react-select__multi-value__remove {
    border-radius: 25px !important;
  }

  .react-select__indicator {
    color: black !important;

    &:hover {
      color: inherit !important;
    }
  }

  .react-select__menu {
    z-index: 10 !important;
    margin-top: 0 !important;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0px 2px 14px 0px #00000014 !important;
  }

  .react-select__option {
    background-color: transparent !important;
    color: black !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    gap: 6px;

    &:hover {
      background-color: #f5f6fa !important;
    }

    .MuiFormControlLabel-root {
      margin-right: 0;

      .MuiCheckbox-root {
        margin-left: 10px;
        padding: 0;
      }
    }
  }

  .react-select__menu-list {
    padding: 0 !important;
  }

  .react-select__multi-value__remove {
    background-color: inherit !important;
    color: inherit !important;
  }

  .text-filter {
    color: #000;
    height: 38px;
    border-radius: 8px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: #f5f6fa !important;
    padding: 0 0.5rem;
    width: 100%;
  }

  .limit-error-message {
    font-size: 12px;
    font-weight: 600;
    color: red;
  }

  .options-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flex-container {
      display: flex;
      gap: 8px;
      align-items: center;

      .clear-button {
        cursor: pointer;

        .cross-icon {
          width: 20px;
          height: 20px;
        }
      }

      .option-text {
        font-weight: 400;
      }
    }

    .weight-filter-container {
      width: 7rem;
    }
  }

  .range-filter-container {
    min-width: 8rem;
    width: 100%;
  }

  .text-filter-container {
    min-width: 14rem;
    width: 100%;
  }

  .select-filter-container {
    min-width: 18rem;
    width: 100%;
  }

  .search-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .username {
      color: #55abeb;
      font-size: 14px;
      font-weight: 600;
      text-decoration-line: underline;
      text-transform: lowercase;
    }

    .followers {
      color: black;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
