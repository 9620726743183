.user-status {
  color: white;
  border-radius: 20px;
  font-size: 12px;
  text-transform: capitalize;
  padding: 7px;
}

.user-active-background {
  background-color: #27ae60;
}

.user-inactive-background {
  background-color: #eb5757;
}
