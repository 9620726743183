.adminPanelContainer {
  .roleSwitchContainer {
    display: flex;
    justify-content: center;

    .roleSwitch {
      display: flex;
      gap: 8px;
      border-radius: 50px;
      background-color: white;
      border: 1px solid #e2e8f0;
      padding: 8px 12px;
    }
  }
}
