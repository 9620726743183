.email-activity-card {
  width: 13.5rem !important;
  height: 10rem !important;
  border: none !important;
  border-radius: 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.activity-list-section {
  background-color: white;
  border-radius: 25px;
  padding: 20px;
}

.activity-list-body {
  height: 19.4rem;
  overflow: auto;
}

.activity-list-section-header {
  background-color: #f6f7fb;
  padding: 10px;
  border-radius: 10px;
}

.header-font-14 {
  font-size: 14px;
  font-weight: 600;
}
.header-font-16 {
  font-size: 16px;
  font-weight: 600;
}

.list-row-header-font-13 {
  font-size: 13px;
  font-weight: 500;
}

.bg-light-white {
  background-color: #f2f2f2;
}

.activity-card-active {
  border: 1px solid #94a3ff !important;
}

.detail-gray-text {
  color: #828282;
  font-size: 11px;
}

.list-row-border-section {
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  padding: 12px 5px;
  cursor: pointer;
}

.activity-title-heading {
  font-size: 0.9rem !important;
  font-weight: 700 !important;
}
.switch-tab-border {
  border: 1px solid #e0e0e0;
}
.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}
.rdrDateRangeWrapper {
  position: absolute;
  z-index: 20;
  right: 100px;
  border: 0.5px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}
