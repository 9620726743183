.miniProfileContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 20px 20px;

  .avatarContainer {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 10px;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .userStatus {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #44b700;
      box-shadow: 0 0 0 2px #fff;
    }
  }

  .profileName {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .profile-name-underline {
    color: #55abeb;
    text-decoration: underline;
    cursor: pointer;
  }

  .userLastSeen {
    font-size: 12px;
    font-style: italic;
    color: #afafaf;
    text-decoration: none !important;
  }
}
