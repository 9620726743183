.searchbar-container {
  flex: 1;
  position: relative;

  .searchbar {
    width: 100%;
    padding: 16px 16px 16px 48px;
    border-radius: 50px;
    border: none;
    background-color: #f6f7fb;

    &:focus,
    &:active,
    &:hover {
      outline: none;
    }
  }

  .search-icon-container {
    width: 18px;
    height: 18px;
    object-fit: cover;
    position: absolute;
    top: 14px;
    left: 17px;

    .search_icon {
      width: 100%;
      height: 100%;
    }
  }
}
