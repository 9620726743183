.attachment {
  display: flex;
  overflow: hidden;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    .reactPlayer {
      width: 100% !important;
      height: 100% !important;

      video {
        object-fit: cover;
      }
    }

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: gray;
    }
  }

  .file {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    background-color: #f5f6fa;
    position: relative;
    border: 1px solid #e2e8f0;

    .icon {
      width: 20px;
      height: 20px;
    }

    .filename {
      font-size: 14px;
      font-weight: 600;
      max-width: calc(100% - 20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .subtext {
      font-size: 12px;
      font-weight: 600;
      color: #828282;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 6px;
    right: 6px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    gap: 6px;

    .icon {
      width: 20px;
      height: 20px;
      color: black;
      cursor: pointer;
    }
  }

  &:hover .controls {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }

  .expandIndicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000cc;
    cursor: pointer;

    .expandIndicatorText {
      font-size: 36px;
      font-weight: 600;
      color: white;
      opacity: 1;
    }
  }
}

.attachment:has(.defaultSpinner) {
  .image {
    opacity: 0.3;
  }
}
