@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(#find-influencers-section) {
  background-color: #f5f6fa !important;

  .intercom-lightweight-app-launcher {
    display: none;
  }
}

#find-influencers-section {
  margin: 0;
  padding: 24px !important;

  font-family: "Open-Sans";

  .control-panel-container {
    border-radius: 10px;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
    gap: 20px;
    margin-bottom: 1rem;

    .actions-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 25px;
    }

    .accordian-container {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    .filters-container {
      border-radius: 25px !important;
      box-shadow: none !important;
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem;
      background-color: #f5f6fa;
      margin: 0 !important;

      &::before {
        display: none !important;
      }

      .MuiAccordionSummary-root {
        min-height: unset !important;
        padding: 0 !important;
      }

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }

      .filter-family-heading {
        color: black;
        font-size: 14px;
        font-weight: 600;
      }

      .icon-container {
        width: 24px;
        height: 24px;
        overflow: hidden;
        cursor: pointer;

        .icon {
          width: 100%;
          height: 100%;
        }
      }

      .accordion-body {
        padding: 0 !important;
      }

      .advanced-filters-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem 0 !important;

        .advanced-filters {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 15px 20px;
          border-radius: 0.5rem !important;
          gap: 12px;
          background-color: white;
        }
      }

      .accordion-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 !important;
        box-shadow: none;
        background-color: inherit;

        &::after {
          display: none;
        }
      }

      .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .total-results-text {
        font-size: 14px;
        font-weight: 600;
      }

      .flex-container {
        margin-left: auto;
        display: flex;
        gap: 1rem;
      }
    }
  }

  .actions-flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cards-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 20px;

    .influencer-card,
    .card-skeleton {
      width: calc((100% - 40px) / 3) !important;
    }
  }

  @media (max-width: 460px) {
    .cards-container {
      margin: 10px 0;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 1280px) {
  #find-influencers-section {
    .cards-container {
      .influencer-card,
      .card-skeleton {
        width: calc((100% - 20px) / 2) !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #find-influencers-section {
    .control-panel-container {
      .actions-container {
        flex-direction: column;

        .platform-switches-container {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  #find-influencers-section {
    .cards-container {
      .influencer-card {
        .card-body {
          .flex-container {
            .info-container {
              .fullname {
                max-width: 120px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
