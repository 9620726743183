.bookmark-modal {
  .modal-container {
    width: 580px;
    position: relative;
    padding: 40px;

    .modal-title {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 5px;
      border: none;
      background: none;
    }

    .form-field-container {
      margin-bottom: 1rem;
    }

    .row-container {
      display: flex;
      gap: 12px;
    }

    .tags-container {
      margin-bottom: 24px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      .tags-button {
        cursor: pointer;
        padding: 0 !important;
        border: none;
        background: transparent !important;
        color: #94a4ff !important;
        font-size: 14px;
        font-weight: 700;
        margin-right: 12px;
      }

      .tag-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 3px 8px;
        border-radius: 50px;

        .name {
          color: white;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
        }

        .remove-button {
          cursor: pointer;
          background-color: white !important;
          border-radius: 50%;
          border: none;
          padding: 0 !important;
          width: 12px;
          height: 12px;
        }
      }
    }

    .limit-exceeded-text {
      display: flex;
      align-items: end;
      gap: 6px;
      padding: 15px 24px;
      background-color: #fdeddb;
      border-radius: 10px;
      margin-bottom: 1rem;

      .icon {
        width: 24px;
        height: auto;
      }

      .text {
        font-weight: 600;
        font-size: 14px;
      }

      .upgrade-button {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
