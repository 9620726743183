.creator-filter-section {
  .select__control {
    width: 140px !important;
    font-size: 13px;
    font-weight: 200;
  }
  .select__placeholder {
    font-size: 12px;
    color: #000000;
    font-weight: 200;
  }
  .select__option {
    font-size: 12px !important;
    cursor: pointer;
  }
  .select__clear-indicator,
  .select__dropdown-indicator {
    padding: 0 !important;
    cursor: pointer;
  }
}
.h-60vh {
  height: 60vh !important;
}
.h-100vh {
  height: 100vh !important;
}
.w-170px {
  width: 170px;
}
.username-search-bar {
  margin-top: -9px;
  width: 300px;
}

.campaign-pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .pagination {
    margin-bottom: 0 !important;
    display: flex;
    gap: 12px;

    .page-item {
      .page-link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 50% !important;
        background-color: white !important;
        color: #8392ab;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        &:focus,
        &:active,
        &:hover {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }

    .page-item.active > .page-link {
      font-weight: 700;
      background-color: #94a3ff !important;
      color: white !important;
    }
  }
}
