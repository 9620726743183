@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(#manage-influencers-section) {
  background-color: #f5f6fa !important;
}

#manage-influencers-section {
  margin: 0;
  padding: 24px !important;

  font-family: "Open-Sans";

  .control-panel-container {
    border-radius: 10px;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
    gap: 25px;
    margin-bottom: 1rem;

    .actions-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 25px;

      .primary-button {
        border: none;
        font-weight: 600;
        border-radius: 33px;
        padding: 16px 36px;
        font-size: 14px;
        border: 1px solid black;
        background-color: black;
        color: white;
      }

      .submit-button {
        color: white;
        background-color: black;
        padding: 15px 45px;
        border: none;
        border-radius: 50px;
        margin-top: 0 !important;
      }
    }
  }

  .cards-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
    gap: 20px;

    .influencer-card,
    .card-skeleton {
      width: calc((100% - 40px) / 3);
    }
  }

  @media (max-width: 460px) {
    .cards-inner-container {
      margin: 10px 0;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 1280px) {
  #manage-influencers-section {
    .cards-container {
      .influencer-card,
      .card-skeleton {
        width: calc((100% - 20px) / 2);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #manage-influencers-section {
    .control-panel-container {
      .actions-container {
        flex-direction: column;

        .platform-switches-container {
          justify-content: center;
        }

        .search-bar-container {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }

    .cards-container {
      .influencer-card {
        .card-body {
          .flex-container {
            .image-container {
              width: 50% !important;
              height: 150px !important;
              object-fit: cover;

              .image {
                height: 100% !important;
                width: 100% !important;
              }
            }

            .info-container {
              width: 50% !important;
            }
          }

          .dropdown-container {
            top: 15px;
            right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  #manage-influencers-section {
    .cards-container {
      .influencer-card {
        .card-body {
          .flex-container {
            .info-container {
              .fullname {
                max-width: 120px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
