.order-detail-list-row-btn {
  color: black;
  padding: 5px;
  border-radius: 20px;
  font-size: 12px;
  width: 70px !important;
  text-align: center;
}
.orderDetailPendingBg {
  background-color: rgba(242, 153, 62, 0.2);
  border: 1px solid #f2993e;
}
.orderDetailPaidBg {
  background-color: rgba(148, 164, 255, 0.2);
  border: 1px solid #94a4ff;
}
