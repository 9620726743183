.influencer-insights-modal {
  .audience-container {
    margin-bottom: 1.5rem;

    .heading {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 14px;
      color: black;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .graphs-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;

      .half-width {
        width: 48.5%;
      }

      .graph {
        background-color: #f6f7fb;
        border-radius: 10px;
        height: 250px;
        padding: 1rem 1rem 5rem 1rem;

        .primary-text {
          color: #172b4d;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        .graph-flex-container {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .legend {
          .label {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            .color {
              width: 16px;
              height: 16px;
              margin-right: 8px;
              border-radius: 50%;
            }

            .text {
              font-size: 12px;
              color: black;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  #influencer-modal {
    .graph-container-half-width {
      width: 100% !important;
    }
  }
}
