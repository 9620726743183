.influencer-insights-modal {
  .posts-container {
    margin-bottom: 1rem;

    .heading {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .gallery {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .post {
        position: relative;
        border-radius: 10px;
        cursor: pointer;
        border: 1px solid #e2e8f0;

        .image-container {
          width: 240px;
          height: 240px;
          border-radius: 10px;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .post-type {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;

          .icon {
            width: 24px;
            height: 24px;
          }
        }

        .post-date {
          position: absolute;
          top: 200px;
          left: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          font-size: 12px;
          font-weight: 400;
          border-radius: 10px;
          padding: 6px 12px;
        }

        .reactions-container {
          display: flex;
          gap: 1rem;
          padding: 12px;

          .reaction {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 4px;

            .icon-container {
              width: 16px;
              height: 16px;

              .icon {
                width: 100%;
                height: 100%;
              }
            }

            .value {
              color: black;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
