@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(.registerPage) {
  background-color: white !important;
}

.registerPage {
  font-family: "Open-Sans";
  display: flex;

  .leftColumn {
    width: 40%;
  }

  .rightColumn {
    width: 60%;
  }

  .backgroundContainer {
    display: flex;
    flex-direction: column;
    background-image: url("../../../assets/images/pink-background.png");
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;

    .heading {
      color: #000;
      text-align: center;
      font-size: 35px;
      font-weight: 700;
      margin: 4rem 0 3rem 0;
      font-family: "Balgin-Bold";

      .special_word {
        opacity: 1;
        color: #ce0f69;
        transition: opacity 1s ease-in-out;
      }

      .special_word.show {
        opacity: 0;
      }
    }

    .imageContainer {
      text-align: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0 5%;

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 20%;

  .logoContainer {
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .roleSwitchContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .roleSwitch {
      display: flex;
      border-radius: 50px;
      background-color: #f5f6fa;
      width: 400px;
      padding: 8px 14px;
    }

    .roleSwitch > .defaultButton {
      width: 50%;
    }
  }

  .usernameText {
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;

    .username {
      color: #ce0f69;
    }

    .mobile-text {
      display: none;
    }

    @media screen and (max-width: 650px) {
      .desktop-text {
        display: none;
      }
      .mobile-text {
        display: inline;
      }
    }
  }

  .googleAuth {
    width: 100%;
    margin-bottom: 1rem;
  }

  .formFieldsContainer {
    width: 100%;
    margin-bottom: 1rem;
  }

  .defaultTextInput {
    margin-bottom: 1rem;
  }

  .termsContainer {
    margin-bottom: 1rem;

    .flexContainer {
      display: flex;
      gap: 6px;
      margin-bottom: 5px;
      align-items: center;

      .link {
        color: #000;
      }
    }

    .errorContainer {
      font-size: 12px;
      color: red;
    }
  }

  .registerButton {
    width: 100%;
    margin-bottom: 1rem;
  }

  .recaptchaContainer {
    margin-bottom: 1rem;

    .errorContainer {
      font-size: 12px;
      color: red;
    }
  }

  .loginText {
    margin-bottom: 1rem;

    .link {
      color: black;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.formSubmittedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
  gap: 3rem;

  .heading {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }

  .description {
    text-align: center;
    font-size: 20px;
    font-weight: 400;

    .link {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

@media (max-width: 1200px) {
  .registerPage {
    .leftColumn {
      display: none !important;
    }

    .rightColumn {
      width: 100% !important;

      .registerForm {
        margin: 2rem 25%;
      }
    }
  }
}

@media (max-width: 768px) {
  .registerPage {
    .registerForm {
      margin: 2rem 20% !important;
    }
  }
}

@media (max-width: 576px) {
  .registerPage {
    .registerForm {
      margin: 1rem 5% !important;

      .roleSwitchContainer {
        .roleSwitch {
          width: 350px;
        }
      }
    }
  }
}
