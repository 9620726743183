.creatorOnboardingFormImagesStep {
  width: 100%;

  .headerFlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .heading {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .banner {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
    border: 1px solid #94a4ff;
    background-color: #94a4ff33;
    padding: 1rem;
    border-radius: 10px;
  }

  .selectedImagesContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-right: -16px;

    .coverImage {
      flex: 1 1 calc(33.33% - 16px);
      max-width: calc(33.33% - 16px);
    }
  }

  .creatorImagesLoadingText {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  .creatorImagesContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
    margin-right: -6px;

    .creatorImage {
      flex: 1 1 calc(16.66% - 6px);
      max-width: calc(16.66% - 6px);
    }
  }
}

@media (max-width: 1230px) {
  .creatorImagesContainer {
    .creatorImage {
      flex-basis: calc(20% - 6px) !important;
      max-width: calc(20% - 6px) !important;
    }
  }
}

@media (max-width: 950px) {
  .creatorOnboardingFormImagesStep {
    .imageDropzone {
      display: none;
    }
  }
}

@media (max-width: 850px) {
  .selectedImagesContainer {
    .coverImage {
      flex-basis: calc(50% - 16px) !important;
      max-width: calc(50% - 16px) !important;
    }
  }
}

@media (max-width: 800px) {
  .creatorImagesContainer {
    .creatorImage {
      flex-basis: calc(25% - 6px) !important;
      max-width: calc(25% - 6px) !important;
    }
  }
}

@media (max-width: 650px) {
  .selectedImagesContainer {
    .coverImage {
      height: 430px !important;
    }
  }
}

@media (max-width: 600px) {
  .creatorImagesContainer {
    .creatorImage {
      flex-basis: calc(33.33% - 6px) !important;
      max-width: calc(33.33% - 6px) !important;
    }
  }
}

@media (max-width: 550px) {
  .creatorImagesContainer {
    .creatorImage {
      height: 200px !important;
    }
  }

  .selectedImagesContainer {
    .coverImage {
      height: 380px !important;
    }
  }
}

@media (max-width: 470px) {
  .creatorImagesContainer {
    .creatorImage {
      height: 180px !important;
    }
  }

  .selectedImagesContainer {
    .coverImage {
      height: 330px !important;
    }
  }
}

@media (max-width: 440px) {
  .selectedImagesContainer {
    .coverImage {
      height: 280px !important;
    }
  }
}
