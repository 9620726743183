.brandDetailsModal {
  .MuiPaper-root {
    max-height: unset !important;
    max-width: unset !important;
  }

  .modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    padding: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .heading {
        font-size: 18px;
        font-weight: 700;
      }

      .closeButton {
        cursor: pointer;
        width: 30px;
        height: 30px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .brandDetailsForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .editAvatarContainer {
        position: relative;
        z-index: 5;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .clickableArea {
          display: inline-block;
          cursor: pointer;
        }

        .avatarContainer {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          overflow: hidden;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .editIcon {
          position: absolute;
          z-index: 10;
          bottom: -5px;
          right: 170px;
        }

        .imageUploader {
          display: none;
        }
      }

      .formFieldContainer {
        .label {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .errorMessage {
        color: rgb(211, 47, 47);
        font-weight: 400;
        font-size: 0.75rem;
        margin: 2px 0;
      }
    }

    .buttonGroup {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
