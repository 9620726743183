.imageCard {
  width: 165px;
  height: 208px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .labelContainer {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 20px;

    .label {
      font-size: 20px;
      font-weight: 600;
      color: white;
    }
  }

  .actionButton {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
