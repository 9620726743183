.orderControls {
  .profileContainer {
    .mainHeading {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .profileFlexContainer {
      display: flex;
      align-items: center;
      gap: 12px;

      .avatarContainer {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profileInnerFlexContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .profileName {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        .profile-name-underline {
          color: #55abeb;
          text-decoration: underline;
          cursor: pointer;
        }

        .ratingText {
          font-size: 12px;
          font-weight: 600;
          color: #afafaf;
        }
      }
    }
  }
}
