.status-select-menu {
  font-size: 13px;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 3px 14px;
  margin-top: 5px;
}

.active-menu-text {
  background-color: #28ae60;
  color: white !important;
}
.inactive-menu-text {
  background-color: #f9fafb;
  color: #475264 !important;
}
.order-list-row-btn {
  color: #ffff;
  padding: 8px;
  border-radius: 20px;
  font-size: 12px;
  width: 115px;
  text-align: center;
}
.order-action-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 8px;
  border-radius: 20px;
  font-size: 12px;
  width: 140px;
  text-align: center;
}
.pending-status-bg {
  background-color: #f2993e;
}
.in_progress-status-bg {
  background-color: #55abeb;
}
.completed-status-bg {
  background-color: #27ae60;
}
.action_required-status-bg {
  background-color: black;
}
.work_submitted-status-bg {
  background-color: #55abeb;
}
.cancelled-status-bg {
  background-color: #eb5757;
}
.declined-status-bg {
  background-color: #eb5757;
}
.expired-status-bg {
  background-color: #eb5757;
}
