@font-face {
  font-family: "Balgin-Bold";
  src: url("../../../assets/fonts/Balgin/Balgin/Fontspring-DEMO-balgin-bold.otf") format("opentype");
}

@font-face {
  font-family: "Balgin-Regular";
  src: url("../../../assets/fonts/Balgin/Balgin/Fontspring-DEMO-balgin-regular.otf") format("opentype");
}

.payment-main-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  background-image: linear-gradient(#fee3ff, #f3f6fe);
  min-height: calc(100vh - 85px);
}
.payment-side-section {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  padding: 75px 80px 0 80px;
}
.main-heading {
  font-family: "Balgin-Bold";
  font-size: 28px;
  max-width: 400px;
}
.charge-logo {
  width: 300px;
}
.credit-text {
  font-size: 26px;
  font-weight: bold;
  width: 270px;
  margin-left: 50px;
}
.credit-text-color {
  color: #94a4ff !important;
}
.hive-influence-logo {
  margin-left: 70px;
  margin-top: -40px;
}
.avatar-container {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.cursor-pointer {
  cursor: pointer;
}
.font-13 {
  font-size: 13px;
}
.email-header {
  color: #909090;
  font-size: 12px;
  font-weight: 400;
}
.payment-alert-section {
  background-color: #feeeee;
  color: #f65353;
  border-radius: 20px;
  padding: 10px 25px;
  font-size: 14px;
}
.alert-icon {
  width: 25px;
}

@media (max-width: 1100px) {
  .payment-main-section {
    .payment-side-section,
    .payment-left-side-section {
      padding: 24px !important;
    }
  }
}

@media (max-width: 850px) {
  .payment-main-section {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .payment-side-section {
      padding: 16px !important;

      .main-heading {
        font-size: 32px !important;
      }

      .main-sub-heading {
        font-size: 20px !important;
        font-weight: 500 !important;
        font-family: "Balgin-Regular" !important;
      }

      .charge-logo {
        width: 200px !important;
      }
    }

    .payment-left-side-section {
      padding: 16px !important;
      width: 100% !important;
    }

    .stripe-form-container {
      padding: 16px !important;
    }
  }
}

.main-sub-heading {
  .mobile-text {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .desktop-text {
      display: none;
    }
    .mobile-text {
      display: inline;
    }
  }
}
