.account-confirmation-screen {
  background-image: url("../../../assets/images/purple-background.svg");
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 116px;
      height: 128px;
      margin-bottom: 3rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      color: black;
      font-size: 45px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
    }

    .link {
      color: #a0a3bd;
      font-size: 20px;
      font-weight: 600;
      text-align: center;

      span {
        cursor: pointer;
        color: #ce0f69;
        text-decoration: underline;
      }
    }
  }
}
