@keyframes fillAnimation {
  from {
    width: 0;
  }
  to {
    width: inherit;
  }
}

.percentage-graph {
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  height: 250px;

  .graph-heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .graph-rows-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .graph-row {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .flex-container {
        display: flex;
        justify-content: space-between;

        .key {
          font-size: 14px;
          font-weight: 400;
        }

        .value {
          font-size: 14px;
          font-weight: 400;
          color: gray;
        }
      }

      .percentage-bar {
        width: 100%;
        background-color: #D8DEFF;
        transition: background-color 0.3s;
        border-radius: 10px;
        height: 10px;
        position: relative;
        overflow: hidden;

        &:hover {
          background-color: darken(#D8DEFF, 10%);

          .percentage-fill {
            background-color: darken(#94a4ff, 10%);
          }
        }

        .percentage-fill {
          background-color: #94a4ff;
          transition: background-color 0.3s;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          animation: fillAnimation 0.5s ease-out forwards;
        }
      }
    }
  }
}
