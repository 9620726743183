.react-kanban-column-header {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1px;
  }

  .icon-container {
    width: auto;
    height: auto;
    overflow: hidden;

    .icon {
      width: auto;
      height: auto;
    }
  }

  .dropdown-container {
    margin-left: auto;

    .dropdown-toggle {
      background-color: transparent;
      border: none !important;

      &::after {
        display: none !important;
      }

      &:focus,
      &:active,
      &:hover {
        background-color: transparent !important;
      }
    }

    .dropdown-menu {
      border-color: white;
      border-radius: 10px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);

      .dropdown-item {
        .item-container {
          display: flex;
          gap: 14px;

          .text {
            color: #11142d;
            font-size: 16px;
            font-weight: 400;
          }
        }

        &:focus,
        &:active,
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}
