.creatorOnboardingFormDetailsStep {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .mainHeading {
    margin-bottom: 0 !important;
  }

  .editAvatarContainer {
    width: fit-content;
    position: relative;
    z-index: 5;

    .clickableArea {
      display: inline-block;
      cursor: pointer;
    }

    .avatarContainer {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .editIcon {
      position: absolute;
      z-index: 10;
      bottom: -5px;
      right: 0;
    }

    .imageUploader {
      display: none;
    }
  }

  .formFieldContainer {
    .label {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .genderSelectContainer {
    .label {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 3px;
    }

    .flexContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .optionCard {
        flex: 1 1 calc(33.33% - 10px);
        max-width: calc(33.33% - 10px);
      }
    }
  }

  .errorMessage {
    color: rgb(211, 47, 47);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 2px 0;
  }
}

@media (max-width: 630px) {
  .genderSelectContainer {
    .optionCard {
      flex: 1 1 calc(50% - 10px) !important;
      max-width: calc(50% - 10px) !important;
    }
  }
}

@media (max-width: 535px) {
  .formFieldContainer {
    .label {
      span {
        display: none !important;
      }
    }
  }
}
