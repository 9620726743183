body:has(.creatorEditProfile) {
  background-color: #f6f7fb !important;
}

.creatorEditProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 1035px) {
    background-color: white;
  }

  .pageHeading {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    width: 993px;

    @media (max-width: 1035px) {
      display: none;
    }
  }

  .creatorProfileWizard {
    display: flex;
    gap: 20px;
    padding: 0 !important;

    @media (max-width: 1035px) {
      flex-direction: column;
    }

    .stepContainer {
      width: 602px !important;
      padding: 40px !important;

      .creatorOnboardingFormDetailsStep {
        .genderSelectContainer {
          .flexContainer {
            flex-wrap: wrap;
          }

          .optionCard {
            flex-basis: calc(50% - 10px) !important;
          }
        }
      }

      .creatorOnboardingFormPlatformsStep {
        .creatorCard {
          flex-basis: calc(100%) !important;
        }
      }

      .creatorOnboardingFormPackagesStep {
        .creatorPackageCard,
        .addPackageButton {
          flex-basis: 100% !important;
        }
      }

      .creatorOnboardingFormImagesStep {
        .selectedImagesContainer {
          gap: 5px !important;
        }

        .coverImage,
        .imageSkeleton,
        .imageOverlay {
          height: 240px !important;
          width: 170px !important;
        }
      }
    }
  }
}
