@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

.influencer-insights-modal {
  .MuiPaper-root {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .influencer-insights {
    padding: 1rem;
    overflow: hidden;
    position: relative;
    font-family: "Open-Sans" !important;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .refresh-insights-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        .refresh-report-text {
          color: #496a9b;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
        }

        .MuiButtonBase-root {
          gap: 0.5rem;
        }
      }

      .close-button {
        border: none;
        background: none;
        margin-left: auto;
      }
    }
  }

  .main-tab-switch-container {
    display: inline-flex;
    border-radius: 25px;
    background-color: #f5f6fa;
    padding: 0.5rem 1rem;

    .main-tab-switch {
      width: 135px;
      height: 35px;
      min-height: 35px;
      border-radius: 25px;
      background-color: #f5f6fa;
      font-size: 14px;
      font-weight: 400;
    }

    .Mui-selected {
      color: black;
      background-color: white;
    }

    .MuiTabs-indicator {
      display: none !important;
      background-color: black;
      border-radius: 10px 10px 0px 0px;
    }
  }

  .main-tab-content {
    padding: 1rem 0 !important;

    .secondary-tab-switch-container {
      margin-left: 24px;

      .secondary-tab-switch {
        font-size: 14px;
        font-weight: 400;
        min-width: unset !important;
      }

      .Mui-selected {
        color: black;
      }
    }

    .secondary-tab-content {
      border-radius: 10px;
      border: 1px solid #e2e8f0;
      height: calc(100vh - 255px) !important;
      overflow-y: auto;
      padding: 1rem;
    }

    .MuiTabs-indicator {
      background-color: black;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
