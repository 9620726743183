.brandOrderSubmissions {
  .submissionsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 20px;

    .submissionContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .headerFlexContainer {
        display: flex;
        gap: 8px;
        align-items: center;

        .submissionIndex {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #94a4ff;
          font-size: 20px;
          font-weight: 600;
          padding: 20px;
        }

        .submissionHeading {
          font-size: 18px;
          font-weight: 600;

          span {
            font-size: 12px;
            font-weight: 600;
            color: #000000aa;
          }
        }
      }

      .contentFlexContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        border-left: 1px solid #e2e8f0;
        padding: 0 20px 40px 20px;
        margin-left: 20px;

        .uploadedFilesContainer {
          display: flex;
          gap: 8px;
          width: 100%;
          flex-wrap: wrap;

          .attachment {
            flex-basis: calc(25% - 6px) !important;
            height: 150px;

            .image,
            .video,
            .file {
              border-radius: 10px;
            }
          }
        }

        .submissionStatusContainer {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-radius: 10px;
          width: 60%;
          background-color: #e2e8f0;
          padding: 16px;

          .statusText {
            font-size: 14px;
            font-weight: 700;
            color: #000000;
          }

          .statusDescription {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            text-wrap: wrap;
          }
        }

        .submissionStatusContainerSuccess {
          background-color: #d5f5c1;

          .statusText {
            color: #016908;
          }
        }

        .submissionStatusContainerDanger {
          .statusText {
            color: #f65353;
          }
        }

        .buttonGroup {
          display: flex;
          gap: 12px;
        }
      }
    }

    .submissionsEmptyView {
      width: 100%;
      text-align: center;

      .emptyViewImage {
        width: 200px;
        height: 130px;
      }

      .emptyViewHeading {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      .emptyViewDescription {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
