.brandOrderDetails {
  padding: 20px;

  .orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .orderTitle {
      font-size: 24px;
      font-weight: 700;
    }

    .orderStatus {
      font-size: 14px;
      font-weight: 700;
      color: #000000;
    }
  }

  .rowContainer {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .columnContainerLeft {
      flex-basis: calc(75% - 5px) !important;
      border: 1px solid #e2e8f0;
      border-radius: 10px;
      padding-top: 20px;

      .tabsContainer {
        display: flex;
        flex-direction: column;

        .tabs {
          display: flex;
          gap: 20px;
          padding: 0 20px;
          border-bottom: 1px solid #e2e8f0;

          .tabSwitch {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 10px;
            cursor: pointer;
            opacity: 30%;

            &.tabSwitchActive {
              opacity: 100%;
              border-bottom: 4px solid black;
            }
          }
        }

        .tabContent {
          padding: 20px;
        }
      }
    }
  }
}
