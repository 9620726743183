.template-header-section {
  .searchbar-container {
    position: relative;
    width: 40%;
    .searchbar {
      width: 100%;
      padding: 10px 10px 10px 35px;
      border-radius: 50px;
      border: 0.5px solid #e2e8f0;
      background-color: #ffffff;

      &:focus,
      &:active,
      &:hover {
        outline: none;
      }
    }

    .search-icon-container {
      width: 15px;
      height: 15px;
      object-fit: cover;
      position: absolute;
      top: 7px;
      left: 17px;

      .search-icon {
        width: 100%;
        height: 100%;
      }
    }
    input {
      font-size: 13px;
    }
  }
}

.template-list-section {
  background-color: white;
  border-radius: 25px;
  padding: 15px 10px;

  .template-list-section-header {
    background-color: #f6f7fb;
    padding: 15px 30px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .template-list-body {
    height: 29rem;
    overflow: auto;
  }
  .template-list-row-border-section {
    border: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    border-radius: 10px;
  }
}
