/* ImpersonationHeader.css */
.impersonationBanner {
  background-color: #94a4ff; /* Yellow background for visibility */
  color: #000; /* Black text */
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  font-family: sans-serif;
}

.bannerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.message {
  font-size: 16px;
  font-weight: 400;
}

.font-17 {
  font-size: 17px;
}
