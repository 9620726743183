.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.from-label {
  position: absolute;
  font-size: 13px;
  z-index: 100;
  top: 15px;
  left: 10px;
  color: #6d6d6d;
}
.to-input {
  padding: 12px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  outline: none !important;
  background-color: white !important;
  box-shadow: none !important;
}
.pl-35 {
  padding-left: 35px !important;
}

.to-input::placeholder {
  color: #4f4f4f;
  font-size: 13px;
  font-weight: 200;
}

.send-email-spinner-position {
  position: absolute;
  z-index: 100;
  top: 14px;
  right: 105px;
}

.email-compose-lading-container {
  min-height: 300px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
