.influencer-insights-modal {
  .half-width {
    width: 48.5%;
  }

  .graphs-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;

    .graph {
      background-color: #f6f7fb;
      border-radius: 10px;
      height: 250px;
      padding: 1rem 1rem 3rem 1rem;

      .primary-text {
        color: black;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }
  }

  .metrics-container {
    border-radius: 10px;
    border: 1px solid #e2e8f0;
    padding: 1rem;

    .primary-text {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .flex-container {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;

      .metric {
        color: #133261;
        font-size: 12px;
        font-weight: 400;
        border-radius: 25px;
        background: #f6f7fb;
        padding: 3px 8px;
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  #influencer-modal {
    .graph-container-half-width {
      width: 100% !important;
    }
  }
}
