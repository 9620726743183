.defaultRangeSlider {
  .legend {
    display: flex;
    justify-content: space-between;

    .min,
    .max {
      font-size: 16px;
      font-weight: 600;
      line-height: 25.92px;
    }
  }

  .MuiSlider-root {
    height: 6px;
    padding: 6px 0 0 0;
  }

  .MuiSlider-track {
    background-color: #94a4ff;
    border: none;
  }

  .MuiSlider-thumb {
    background-color: #94a4ff;
    box-shadow: none !important;
  }

  .MuiSlider-rail {
    background: #94a4ff;
  }

  .MuiSlider-valueLabelOpen {
    background-color: white !important;
    width: 60px;
    height: 50px;
    box-shadow: 0px 4px 10px 0px #00000029;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
  }

  .MuiSlider-valueLabelCircle {
    color: black;
    font-size: 14px;
    font-weight: 600;
    line-height: 25.92px;
  }
}
