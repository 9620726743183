.toast-outer-container {
  width: unset !important;
}

.toast-inner-container {
  border-radius: 10px !important;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.16) !important;
  padding: 0.5rem !important;
}

.toast-body {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 1rem !important;
  min-width: 225px !important;
}
