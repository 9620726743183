header {
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family: "Open-Sans";
  background-color: white;

  .mainHeader {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 2px solid #e2e8f0;

    .logoContainer {
      width: 180px;
      height: 36px;
      cursor: pointer;
      margin-right: 2rem;

      .logo {
        width: 100%;
        height: 100%;
      }
    }
  }

  .navLinks {
    margin: 0 1rem;
    display: flex;
    gap: 0.8rem;

    .navLink {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      padding: 30px 15px;
      opacity: 0.3;
      border: none;
      position: relative;
      cursor: pointer;
      background-color: white;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: white;
      }

      &.navLinkActive {
        opacity: 1 !important;

        &:after {
          background-color: black;
          transform: scaleX(1);
        }
      }
    }
  }

  .secondaryLinksContainer {
    padding-top: 10px;
    border-bottom: 2px solid #e2e8f0;
    background-color: white;

    .navLink {
      width: 70px;
      font-size: 13px;
      padding: 10px 0;

      &:after {
        height: 3px;
      }
    }
  }

  .profileContainer {
    display: flex;
    gap: 0.5rem;
    margin-left: auto;

    .profileFlexContainer {
      display: flex;
      gap: 0.5rem;

      .avatarContainer {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profileInfo {
        display: flex;
        flex-direction: column;
        align-items: start;

        .name {
          color: #000;
          font-size: 14px;
          font-weight: 700;
        }

        .email {
          color: #909090;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .MuiButton-endIcon {
      margin: 0 0 1px 0 !important;
    }

    .MuiButton-root {
      border-radius: 0;
      align-items: end;
      gap: 0;
    }
  }

  .defaultButton {
    display: none;
  }
}

.mobileWarningBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #94a4ff;

  .heading {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
}

@media (max-width: 773px) {
  .mainHeader {
    padding: 0 10px !important;

    .logoContainer {
      width: 180px !important;
      margin-right: 0px !important;
    }

    .navLink {
      padding: 30px 10px !important;
    }
  }
}

@media (max-width: 690px) {
  .mainHeader {
    justify-content: space-between;
    padding: 16px 20px !important;

    .navLinks {
      display: none !important;
    }

    .profileContainer {
      display: none !important;
    }

    .defaultButton {
      display: flex !important;
    }
  }
}
