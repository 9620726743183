.defaultMenuButton {
  min-width: unset !important;
  padding: 0 !important;
  box-shadow: none !important;
  font-size: 14px !important;
  text-transform: none !important;
  color: black !important;
  gap: 4px;

  .MuiButton-endIcon {
    margin: 0 !important;
  }
}

.defaultMenu {
  .MuiMenuItem-root {
    padding: 10px 50px 10px 20px !important;
  }

  .MuiMenu-list {
    padding: 0 !important;
  }
}
