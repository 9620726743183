@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(.forgot-password-page) {
  background-color: white !important;
}

.forgot-password-page {
  overflow-x: hidden;
  font-family: "Open-Sans";

  .logo-container {
    width: auto;
    height: auto;
    margin: 2rem 0 0 4rem;

    .logo {
      width: auto;
      height: auto;
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .form-field-container {
      margin-bottom: 30px;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 500px;
      margin: 0 20px;

      .icon-container {
        width: auto;
        height: auto;
        text-align: center;
        margin-bottom: 48px;

        .icon {
          width: auto;
          height: auto;
        }
      }

      .primary-text {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
      }

      .secondary-text {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }

      .link {
        color: black;
        font-weight: bold;
      }

      a[class="button"] {
        text-decoration: none;
        background-color: black;
        color: white;
        border: none;
        font-weight: bold;
        border-radius: 25px;
        padding: 12px 96px;
      }
    }
  }

  @media (max-width: 768px) {
    .logo-container {
      text-align: center !important;
      margin: 2rem 0 0 0;
    }
  }

  @media (max-width: 576px) {
    .icon {
      width: 135px !important;
    }
  }
}
