.creatorFormFloatingBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px -4px 20px 0px #0000001a;
  z-index: 1000;

  .progressBar {
    background-color: #94a4ff;
    height: 6px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    padding: 12px 8%;

    .nextButton {
      margin-left: auto;
    }
  }
}

@media (max-width: 950px) {
  .creatorFormFloatingBar {
    .defaultButton {
      width: 111px !important;
    }

    .controls {
      padding: 12px 5% !important;
    }
  }
}
