.usernameSearchbar {
  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .select__control {
    border: 1px solid #e2e8f0 !important;
    border-radius: 25px;
    box-shadow: none;
    background-color: white;
    height: 49px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 12px;
  }

  .select__value-container {
    padding: 2px 0px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    margin-top: 0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 14px 0px #00000014;
  }

  .select__option {
    background-color: transparent;
    color: black;
    border-radius: 0;

    &:hover {
      background-color: #f5f6fa;
    }
  }

  .select__menu-list {
    padding: 0;
    max-height: 250px;
  }

  .select__indicators {
    margin-right: 0px;
    cursor: pointer;
  }

  .userOption {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .username {
      color: #55abeb;
      font-size: 14px;
      font-weight: 600;

      span {
        font-size: 12px;
        color: black;
        text-decoration: none;
        text-transform: none;
        font-weight: 400;
      }
    }

    .followers {
      color: black;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .errorMessage {
    color: rgb(211, 47, 47);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 2px 0;
  }
}
