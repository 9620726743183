.brandOrderMessages {
  .messagesContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 425px);
    overflow: auto;
    margin-bottom: 20px;

    .messageLoader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message {
      .header {
        display: flex;
        align-items: center;
        gap: 8px;

        .avatarContainer {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 10px;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          font-size: 16px;
          font-weight: 700;

          .timestamp {
            font-size: 12px;
            font-weight: 400;
            margin-left: 5px;
            color: #8392ab;
          }
        }
      }

      .body {
        font-size: 14px;
        font-weight: 400;
        margin-left: 48px;
        margin-bottom: 6px;
        white-space: pre-line;
      }
    }
  }

  .filesContainer + .messageInput {
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0 0 10px 10px !important;
    }
  }
}
