.imageCropper {
  .MuiPaper-root {
    max-height: unset !important;
    max-width: unset !important;
  }

  .modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 600px;
    padding: 1rem 0;

    .heading {
      font-size: 18px;
      font-weight: 700;
      padding: 0 0 1rem 1rem;
    }

    .cropperContainer {
      position: relative;
      height: 400px;
      width: 100%;
      background-color: #fff;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(-45deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%),
        linear-gradient(-45deg, transparent 75%, #ccc 75%);
      background-size: 10px 10px;
      background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
    }

    .controls {
      display: flex;
      width: 100%;
      gap: 1rem;
      padding: 1rem;
      align-items: end;

      .label {
        font-size: 14px;
        font-weight: 400;
      }

      .defaultRangeSlider {
        width: 100%;

        .MuiSlider-thumb {
          width: 12px !important;
          height: 12px !important;
        }

        .MuiSlider-root {
          height: 2px !important;
        }
      }
    }

    .buttonGroup {
      display: flex;
      justify-content: end;
      gap: 1rem;
      padding: 0 1rem 0 0;
    }
  }
}

@media (max-width: 670px) {
  .imageCropper {
    .MuiPaper-root {
      margin: 10px !important;
      flex: 1 1 100% !important;
    }

    .modalContainer {
      width: 100% !important;

      .buttonGroup {
        padding: 0 1rem;

        .defaultButton {
          width: 100% !important;
        }
      }
    }
  }
}
