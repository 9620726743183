@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body {
  background-color: #f5f6fa !important;
}

.account-page {
  font-family: "Open-Sans";

  .profile-container {
    display: flex;
    flex-direction: column;

    .primary-text {
      color: #131313;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .nav-section {
    padding: 40px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
    width: 400px;

    .nav-tabs {
      display: flex;
      flex-direction: column;
      border: none;

      .nav-item {
        .nav-link {
          width: 100%;
          border-radius: 25px;
          border: 1px solid #e2e8f0;
          color: black;
          font-size: 20px;
          font-weight: 400;

          &:focus,
          &:active,
          &:hover {
            border-radius: 25px;
            border: 1px solid #e2e8f0;
          }
        }

        .active {
          background-color: #f6f7fb;
        }
      }
    }
  }

  .nav-section.responsive {
    border-radius: 0 !important;
    width: fit-content;
    width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }

  .nav-content {
    .tab-content {
      .tab-pane {
        padding: 40px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
        width: 500px;

        .primary-text {
          color: #000;
          font-size: 24px;
          font-weight: 700;
        }

        .form {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .form-fields-container {
            margin-bottom: 15px;

            .form-field-container {
              margin-bottom: 30px;
            }
          }

          .submit-button {
            display: flex;
            gap: 8px;
            justify-content: center;
            border-radius: 25px;
            width: 100%;
            background-color: black;
            border: none;
            padding: 12px;

            .text {
              color: white;
              font-weight: bold;
              font-size: 14px;
            }
          }

          .editAvatarContainer {
            width: fit-content;
            position: relative;
            z-index: 5;

            .clickableArea {
              display: inline-block;
              cursor: pointer;
            }

            .avatarContainer {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              overflow: hidden;

              .avatar {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .editIcon {
              position: absolute;
              z-index: 10;
              bottom: -5px;
              right: 0;
            }

            .imageUploader {
              display: none;
            }
          }

          ::-webkit-scrollbar {
            width: 3px !important;
          }

          ::-webkit-scrollbar-thumb {
            background: #aaa !important;
          }
        }
      }
    }
  }

  .menu-btn {
    padding: 0 !important;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
      border: none !important;
    }
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.visible {
      display: block;
    }

    &.hidden {
      display: none;
    }
  }

  @media (max-width: 950px) {
    .nav-section.web {
      display: none;
    }

    .nav-section {
      display: none;
    }

    .h1 {
      display: none;
    }

    .menu-btn {
      display: block !important;
    }
  }

  @media (max-width: 550px) {
    .tab-pane {
      padding: 40px 15px !important;
      width: 92vw !important;
    }
  }
}
