.highlight-section {
  border: 1px solid #94a4ff;
  border-radius: 13px;
  background-color: #94a4ff33;
}
.active-plan-detail {
  padding: 20px;
  width: 320px;
  display: inline-block;
}
.price-text {
  font-size: 40px;
}
.sub-heading-text {
  font-size: 13px;
  font-weight: 300;
}
.detail-text-heading {
  font-size: 16px;
  font-weight: 600;
}
.submit-button {
  border-radius: 25px;
  margin-top: 20px;
  background-color: #000 !important;
  color: white !important;
  padding: 12px 25px;
  border: none;
  font-size: 13px;
}

.default-badge {
  background-color: #f6f7fb;
  padding: 10px;
  border-radius: 50px;
  font-size: 13px;
}
.set-default {
  color: #94a4ff;
  font-size: 13px;
  cursor: pointer;
}
.detail-text {
  color: #343434;
  font-size: 16px;
  font-weight: 400;
}
.cursor-pointer {
  cursor: pointer;
}
.card-bg-black {
  //   background-color: #343434 !important;
  border-radius: 9px;
  padding: 8px;
}
.new-payment-text {
  color: #000000;
  font-size: 15px;
}
.p-80 {
  padding-top: 50px;
  padding-left: 70px;
  padding-right: 70px;
}
.ml-80 {
  margin-left: 80px;
}
.select-field-container {
  .react-select__control {
    border: 1px solid #e2e8f0 !important;
    border-radius: 15px !important;
    padding: 6px !important;
    box-shadow: none !important;
    cursor: pointer;
    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .react-select__control--menu-is-open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__indicator {
    color: black !important;

    &:hover {
      color: inherit !important;
    }
  }

  .react-select__menu {
    z-index: 10 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #e2e8f0 !important;
    box-shadow: none !important;
  }

  .react-select__option {
    background-color: transparent !important;
    color: black !important;
    border-radius: 0 !important;

    &:hover {
      background-color: #e2e8f0 !important;
    }
  }

  .react-select__menu-list {
    padding: 0 !important;
  }
}
.spinner-scale {
  width: 15px !important;
  height: 15px !important;
}

.checkout-tooltip {
  font-family: "Open-Sans" !important;
  background-color: black !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
  padding: 10px !important;

  span::before {
    background-color: black !important;
  }
}
.checkout-platform-switch-container {
  display: flex;
  max-width: 300px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 50px;
  gap: 8px;
  margin: 20px 0;
  position: relative;

  .platform-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px 20px;
    background-color: inherit;
    border: none !important;
    border-radius: 50px;
    width: 200px;
    height: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    .name {
      color: #555f61;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .active {
    background-color: #94a3ff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    .name {
      color: white !important;
    }
  }
  .plan-discount-badge-position {
    position: absolute;
    top: -16px;
    right: 42px;
  }
}
.subscription-note {
  font-size: 14px;
  margin-top: 15px;
}
.font-15px {
  font-size: 15px;
}
.plan-discount-badge {
  background-color: #ce0f69;
  color: white;
  border-radius: 66px;
  font-size: 8px;
  width: 65px;
  padding: 4px;
  text-align: center;
}
.billed-price {
  font-size: 17px;
  font-weight: 500;
}

.higlighted-text {
  color: #ce0f69;
}
.font-12px {
  font-size: 12px;
}
