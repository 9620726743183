@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(.loginPage) {
  background-color: white !important;
}

.loginPage {
  font-family: "Open-Sans";
  display: flex;

  .leftColumn {
    width: 40%;
  }

  .rightColumn {
    width: 60%;
  }

  .backgroundContainer {
    display: flex;
    flex-direction: column;
    background-image: url("../../../assets/images/pink-background.png");
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .heading {
      color: #000;
      text-align: center;
      font-size: 35px;
      font-weight: 700;
      margin: 4rem 0 3rem 0;
      font-family: "Balgin-Bold";

      .special_word {
        opacity: 1;
        color: #ce0f69;
        transition: opacity 1s ease-in-out;
      }

      .special_word.show {
        opacity: 0;
      }
    }

    .imageContainer {
      text-align: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0 5%;

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 20%;

    .logoContainer {
      margin-bottom: 2rem;
      cursor: pointer;
    }

    .heading {
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .googleAuth {
      width: 100%;
      margin-bottom: 1rem;
    }

    .formFieldsContainer {
      width: 100%;
      margin-bottom: 1rem;
    }

    .defaultTextInput {
      margin-bottom: 1rem;
    }

    .loginButton {
      width: 100%;
      margin-bottom: 1rem;
    }

    .linkText {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .link {
        color: #000;
      }
    }

    .recaptchaContainer {
      margin-bottom: 1rem;

      .errorContainer {
        font-size: 12px;
        color: red;
      }
    }
  }
}

@media (max-width: 1200px) {
  .loginPage {
    .leftColumn {
      display: none !important;
    }

    .rightColumn {
      width: 100% !important;

      .loginForm {
        margin: 2rem 25%;
      }
    }
  }
}

@media (max-width: 768px) {
  .loginPage {
    .loginForm {
      margin: 2rem 20% !important;
    }
  }
}

@media (max-width: 576px) {
  .loginPage {
    .loginForm {
      margin: 1rem 5% !important;
    }
  }
}
