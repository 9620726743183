.tags-modal {
  .modal-container {
    width: 500px;
    position: relative;
    padding: 40px;

    .modal-title {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 5px;
      border: none;
      background: none;
    }

    .form-field-container {
      margin-bottom: 2rem;
    }

    .colors-container {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;

      .checkbox-container {
        width: 30px;
        height: 30px;
      }
    }
  }
}
