.fileUploadPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-basis: calc(25% - 6px) !important;
  height: 150px;
  border-radius: 10px;
  background-color: #000000cc;
  padding: 12px;

  .iconsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;

    .icon {
      color: white;
      width: 30px;
      height: 30px;
    }
  }
}
