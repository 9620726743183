.appbar {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  font-family: "Open-Sans";
  background-color: white;

  @media (max-width: 690px) {
    display: block !important;
  }

  .navIcons {
    display: flex;
    justify-content: center;
    padding: 8px 35px;
    align-items: center;
    gap: 36px;

    .navIcon {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &.navIconActive {
        .label {
          color: #000000;
          font-weight: 600;
        }
      }

      .icon {
        width: 24px;
        height: 24px;
      }

      .label {
        font-size: 12px;
        color: #909090;
      }
    }
  }
}

.appbarDrawer {
  .MuiPaper-root {
    border-radius: 0 !important;
    width: 80%;
    max-width: 250px;
    height: 100%;
  }

  .drawerContent {
    height: 100%;
    display: flex;
    flex-direction: column;

    .drawerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #e2e8f0;

      .profileFlexContainer {
        display: flex;
        gap: 8px;
        align-items: center;

        .avatarContainer {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profileInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .fullname {
            color: #000;
            font-size: 14px;
            font-weight: 500;
          }

          .email {
            color: #909090;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      .drawerClose {
        font-size: 24px;
        cursor: pointer;
        color: #909090;
        padding: 4px;
      }
    }

    .drawerItems {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 16px;

      .drawerItem {
        padding: 12px 0;
        font-size: 16px;
        cursor: pointer;
        color: #000000;
        border-bottom: 1px solid #e2e8f0;

        &:last-child {
          border-bottom: none;
        }

        &.logoutItem {
          color: #f04438;
        }
      }
    }
  }
}
