.w-800 {
  width: 800px !important;
}
.p-25 {
  padding: 25px !important;
}

.order-biling-section {
  table {
    width: 100%;
    border-collapse: collapse; /* No cell spacing */
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    a {
      color: #94a4ff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  th {
    background-color: #f6f7fb;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
  }
}
