.defaultDatePicker {
  width: 100%;
  cursor: pointer;

  .label {
    font-weight: 400;
    font-size: 16px;
    color: black;
    margin-bottom: 3px;
  }

  .MuiStack-root {
    padding-top: 0;
  }

  .MuiInputBase-root {
    border-radius: 25px;
    position: relative;
    cursor: pointer;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 25px;
    border: 1px solid #e2e8f0 !important;
  }

  .MuiInputBase-input {
    padding: 13px 20px !important;
    cursor: pointer;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: #f44336 !important;
    }
  }

  .MuiInputBase-multiline {
    padding: 0 !important;
  }

  .MuiInputAdornment-root {
    margin-right: -10px !important;
  }
}
