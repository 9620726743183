.adminPanelContainer {
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mainHeading {
    font-weight: 700;
    font-size: 24px;
  }

  .controlPanel {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;

    .searchbar-container {
      .searchbar {
        padding: 14px 14px 14px 48px !important;
        background-color: white !important;
        width: 50%;
        border: 1px solid #e2e8f0;
      }
    }
  }

  .usersDatatableContainer {
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 4px 10px 0px #0000000a;
    padding: 1rem 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .paginationContainer {
    display: flex;
    justify-content: center;
  }

  .userDatatable {
    .tableHeaderRow {
      background-color: #f6f7fb;
      border-radius: 12px;
      padding: 15px;
      font-weight: 500;
      font-size: 14px;
      align-items: center;

      .tableHeader {
        flex: 1;
        text-align: left;
      }

      .tableHeader:first-child {
        flex: 2 !important;
      }
    }

    .tableDataRow {
      padding: 10px;
      background-color: white;
      border-radius: 12px;
      border: 1px solid #f2f2f2;
      align-items: center;
      .tableData {
        font-weight: 400;
        font-size: 14px;
        text-align: center;

        .profileContainer {
          display: flex;
          gap: 16px;
          align-items: center;

          .avatarContainer {
            border-radius: 35px;
            width: 60px;
            height: 60px;
            object-fit: contain;
            overflow: hidden;

            .avatar {
              width: 100%;
              height: 100%;
            }
          }

          .userName {
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 6px;
            text-align: start;
          }

          .userNameUnderline {
            color: #55abeb;
            text-decoration: underline;
            cursor: pointer;
          }

          .userEmail {
            font-weight: 400;
            font-size: 14px;
            color: #909090;
          }
        }
      }

      .tableData:first-child {
        flex: 2;
      }
    }
  }
}
