.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999999;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .animated-logo {
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 25px;
    box-shadow: 5rem 5rem 5rem 5rem rgba(0, 0, 0, 0.04);
    padding: 1rem;

    .image {
      width: 100%;
      height: 100%;
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999999;
  pointer-events: auto;
}
