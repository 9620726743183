.setup-payment-container {
  padding: 20px 15px 10px 15px;
  background-color: rgba(148, 164, 255, 0.2); // 20% opacity
  border: 1px solid #94a4ff;
  border-radius: 20px;
}

// Text Section
.setup-payment-text {
  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000; // Black text for the heading
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a; // Dark gray for paragraph text
  }
}
.mt--15 {
  margin-top: -15px;
}
