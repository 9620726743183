.creatorProfileWizard {
  padding: 1rem 13% 100px 12%;

  .mainHeading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .creatorFormContainer {
    background-color: white;
    align-self: flex-start;

    .submitButton {
      padding: 0 40px 40px 40px;
    }
  }

  .stepContainer {
    display: none;
  }

  .stepActive {
    display: flex;
    width: 100%;
  }

  .errorMessage {
    color: rgb(211, 47, 47);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 2px 0;
  }
}

@media (max-width: 950px) {
  .creatorProfileWizard {
    padding: 1rem 5% 100px 5%;

    .mainHeading {
      font-size: 20px !important;
      margin-bottom: 12px !important;
    }
  }
}
