.packagesFormModal {
  .MuiPaper-root {
    max-height: unset !important;
    max-width: unset !important;
  }

  .modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 750px;
    padding: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 26px;

      .heading {
        font-size: 18px;
        font-weight: 700;
      }

      .closeButton {
        cursor: pointer;
        width: 30px;
        height: 30px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .flexContainer {
        display: flex;
        gap: 10px;

        .selectInput {
          flex-basis: calc(70% - 10px) !important;
        }

        .defaultTextInput {
          flex-basis: calc(30% - 10px) !important;
        }
      }

      .actionsContainer {
        display: flex;
        justify-content: end;
      }
    }
  }
}

@media (max-width: 820px) {
  .packagesFormModal {
    .MuiPaper-root {
      margin: 10px !important;
      flex: 1 1 100% !important;
    }

    .modalContainer {
      padding: 16px !important;
      width: 100% !important;

      .formContainer {
        .flexContainer {
          flex-direction: column;
        }
      }

      .actionsContainer {
        .defaultButton {
          width: 100% !important;
        }
      }
    }
  }
}
