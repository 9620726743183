.font-15 {
  font-size: 15px;
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}
.font-18 {
  font-size: 18px;
}
.no-campaign-text {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f65353;
}
