.search-bar-filter-container {
  width: 100% !important;

  .react-select__control {
    padding: 0.5rem;
    border: none !important;
    border-radius: 25px !important;
    box-shadow: none !important;
    background-color: #f5f6fa !important;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .react-select__control--menu-is-open {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .react-select__clear-indicator {
    cursor: pointer !important;
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__indicator {
    color: black !important;

    &:hover {
      color: inherit !important;
    }
  }

  .react-select__menu {
    z-index: 10 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #e2e8f0 !important;
    box-shadow: none !important;
  }

  .react-select__option {
    background-color: transparent !important;
    color: black !important;
    border-radius: 0 !important;
    display: flex;
    gap: 6px;

    &:hover {
      background-color: #f5f6fa !important;
    }
  }

  .react-select__menu-list {
    padding: 0 !important;
  }

  .react-select__multi-value__remove {
    background-color: inherit !important;
    color: inherit !important;
  }

  .form-check-input {
    border-radius: 3px !important;
    border: 1px solid #b9b9b9 !important;

    &:checked {
      background-color: black !important;
      border: none !important;
    }

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .react-select__multi-value {
    border-radius: 25px !important;
    padding: 0 3px;
  }

  .react-select__multi-value__remove {
    border-radius: 25px !important;
  }

  .react-select__value-container {
    padding: 2px 0 !important;
  }

  .option {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .username {
      color: #55abeb;
      font-size: 14px;
      font-weight: 600;
      text-decoration-line: underline;
      text-transform: lowercase;
    }

    .followers {
      color: black;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
