.selectInput {
  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;

    span {
      color: #828282;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .select__control {
    border: 1px solid #e2e8f0 !important;
    border-radius: 25px;
    box-shadow: none;
    background-color: white;
    height: 49px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 12px;
  }

  .select__value-container {
    padding: 2px 0px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    margin-top: 0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 2px 14px 0px #00000014;
  }

  .select__option {
    background-color: transparent;
    color: black;
    border-radius: 0;

    &:hover {
      background-color: #f5f6fa;
    }
  }

  .select__menu-list {
    padding: 0;
  }

  .select__indicators {
    margin-right: 0px;
  }

  .select__multi-value {
    border-radius: 25px;
    padding: 0 3px;
  }

  .select__multi-value__remove {
    border-radius: 25px;
  }

  .errorMessage {
    color: rgb(211, 47, 47);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 2px 0;
  }

  .select__menu-list {
    max-height: 200px !important;
  }
}
