.imageOverlay {
  height: 480px;
  width: 340px;
  cursor: grabbing;
  overflow: hidden;
  border-radius: 10px;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
