.header-upgrade-btn {
  color: #ffff;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  background-color: #94a4ff;
  border: none;
}
.border-bottom-light-grey {
  border-bottom: 1px solid #e2e8f0;
}
.w-300px {
  width: 300px;
}
.credit-heading {
  font-size: 16px;
  font-weight: 600;
}
.credit-detail {
  font-size: 16px;
  font-weight: 200;
}
