.orderRequirementsForm {
  margin: 3% 15%;

  .mainHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 40px;
    position: relative;

    .mainHeading {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .formFieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 150px;

    .label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .detailsContainer {
      .options {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .optionCard {
          flex-basis: calc(50% - 10px) !important;
        }
      }
    }

    .questionsContainer {
      .questions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .dosAndDontsContainer {
      .columnsContainer {
        display: flex;
        gap: 40px;

        .column {
          flex-basis: calc(50% - 20px) !important;

          .dos,
          .donts {
            display: flex;
            flex-direction: column-reverse;
            gap: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .floatingControls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px -4px 20px 0px #0000001a;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    padding: 12px 8%;
  }
}
