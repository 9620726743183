.defaultTextInput {
  width: 100%;

  .label {
    font-weight: 400;
    font-size: 16px;
    color: black;
    margin-bottom: 3px;

    span {
      color: #828282;
      font-weight: 300;
      font-size: 14px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px;
  }

  .MuiInputBase-root {
    border-radius: 25px;
    position: relative;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 25px;
    border: 1px solid #e2e8f0;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .MuiInputBase-input {
    padding: 13px 20px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .MuiFormLabel-root {
    color: black !important;
  }

  .floatingLabel {
    .MuiInputBase-input {
      padding: 8.5px 14px;
    }
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: #f44336 !important;
    }
  }

  .MuiInputBase-multiline {
    padding: 0 !important;
  }

  .MuiInputAdornment-root {
    margin-right: -10px !important;
  }

  .characterCount {
    font-size: 14px;
    font-weight: 600;
    color: black;
    position: absolute;
    right: 20px;
    bottom: 12px;
    background-color: white;
  }

  .characterCountExceeded {
    color: red;
  }
}

@media (max-width: 950px) {
  .defaultTextInput {
    .characterCount {
      display: none;
    }
  }
}
