.influencer-insights-modal {
  .influencer-report {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid #e2e8f0;
    background: white;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
    padding: 1rem;
    height: calc(100vh - 140px) !important;
    overflow-y: auto;

    .influencer-profile {
      display: flex;
      justify-content: start;
      gap: 1rem;

      .image-container {
        width: 120px;
        height: 120px;
        overflow: hidden;
        position: relative;

        .image {
          border-radius: 15px;
          width: 100%;
          height: 100%;
        }

        .profile-button {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;

          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .flex-container {
        display: flex;
        flex-direction: column;

        .fullname {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }

        .username {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #55abeb;
          font-size: 16px;
          font-weight: 600;
          text-decoration-line: underline;
          text-transform: lowercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
          margin-bottom: 1rem;

          .profile-icon {
            width: 16px;
            height: 16px;
          }
        }

        .influencer-contact-details {
          display: flex;
          gap: 0.5rem;

          .icon-container {
            width: 30px;
            height: 30px;
            overflow: hidden;
            cursor: pointer;
          }
        }
      }
    }

    .influencer-profile-attributes {
      display: flex;
      flex-wrap: wrap;

      .flex-container {
        gap: 0.5rem;

        &:nth-child(1) {
          flex: 0 0 calc(40%);
        }

        &:nth-child(n + 2) {
          flex: 0 0 calc(30%);
        }

        .key {
          color: #496a9b;
          font-size: 14px;
          font-weight: 600;
        }

        .value {
          color: black;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .influencer-headline {
      border: 1px solid #496a9b;
      border-radius: 10px;
      background-color: #f6f7fb;
      padding: 1rem;

      .value {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .influencer-metrics {
      display: flex;
      flex-wrap: wrap;

      .flex-container {
        text-align: center;
        flex: 0 0 calc(33.33% - 10px);
        margin: 5px;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid #f6f7fb;
        background: #f6f7fb;
        padding: 10px 0;
        margin-bottom: 1rem;

        .value {
          color: #152a4a;
          font-size: 14px;
          font-weight: 700;
        }

        .key {
          color: #496a9b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .boards {
      .flex-container {
        display: flex;
        justify-content: start;
        align-items: end;
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        .icon {
          width: 30px;
          height: 30px;
          margin-right: 0.5rem;
        }

        .heading {
          color: #152a4a;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .board {
        border-radius: 10px;
        border: 1px solid #94a4ff;
        background-color: rgba(148, 164, 255, 0.2);
        color: #000;
        font-size: 14px;
        font-weight: 400;
        padding: 14px 20px;
        margin-bottom: 1rem;
      }
    }

    .notes-container {
      .flex-container {
        display: flex;
        justify-content: start;
        align-items: end;
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        .icon {
          width: 30px;
          height: 30px;
          margin-right: 0.5rem;
        }

        .heading {
          color: #152a4a;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .note {
        border-radius: 10px;
        border: 1px solid #fedc66;
        background: rgba(254, 220, 102, 0.2);
        padding: 16px 20px 30px 16px;
        position: relative;
        margin-bottom: 2rem;

        .text {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }

        .dropdown-container {
          position: absolute;
          bottom: 5px;
          right: 10px;
          cursor: pointer;
        }

        .dropdown-toggle {
          background-color: transparent;
          border: none;
          padding: 0 !important;

          &::after {
            display: none !important;
          }

          .icon-container {
            width: auto;
            height: auto;
            overflow: hidden;

            .icon {
              width: auto;
              height: auto;
            }
          }
        }

        .dropdown-menu {
          padding: 10px 0;
          border-color: white;
          border-radius: 10px;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);

          .dropdown-item {
            display: flex;
            color: #11142d;
            font-size: 16px;
            font-weight: 400;
            padding-right: 60px;
            cursor: pointer;

            .icon-container {
              width: 16px;
              height: 16px;

              .dropdown-icon {
                width: 100%;
                height: 100%;
              }
            }

            .text {
              margin-top: 2px;
              color: #11142d;
              font-size: 16px;
              font-weight: 400;
            }

            &:focus,
            &:active,
            &:hover {
              background-color: inherit;
            }
          }
        }
      }
    }
  }
}
