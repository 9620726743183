.coverImage {
  height: 480px;
  width: 340px;
  cursor: grab;
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imageSkeleton {
    height: 480px;
    width: 340px;
  }

  .imageControls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .closeIcon {
      cursor: pointer;
    }

    .cropIcon {
      cursor: pointer;
    }
  }

  .imageLabel {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: white;
    color: black;
    padding: 6px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .imageControls,
  &:hover .imageLabel {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }

  @media screen and (max-width: 950px) {
    .imageControls {
      opacity: 1;
      pointer-events: auto;
      gap: 8px;

      > *:nth-child(2) {
        display: none;
      }
    }

    .imageLabel {
      display: none;
    }
  }
}
