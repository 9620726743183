.influencer-profile-view-page {
  background-color: #fff;
  padding-bottom: 25px;
  padding-top: 5px;

  .influencer-profile-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255);
    position: relative;

    .profile-picture-container {
      position: relative;
      display: flex;
      margin-top: -60px;
      margin-left: 5px;

      .profile-detail-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        margin-top: 65px;
        padding-bottom: 0px;
        padding-top: 5px;
        width: 977px;

        @media (max-width: 1250px) {
          width: 858px;
        }

        @media (max-width: 1050px) {
          width: 738px;
        }

        @media (max-width: 900px) {
          width: 636px;
        }

        @media (max-width: 770px) {
          width: 516px;
        }

        @media (max-width: 650px) {
          width: 406px;
        }

        @media (max-width: 520px) {
          width: 350px;
        }

        @media (max-width: 450px) {
          width: 300px;
        }

        .influencer-name {
          font-size: 20px;
          font-weight: 600;
          border-right: 3px solid black;
          @media (max-width: 520px) {
            font-size: 14px;
          }
        }
        .influencer-detail {
          font-size: 16px;
          font-weight: 200;
          margin-left: 10px;
          @media (max-width: 520px) {
            font-size: 12px;
          }
        }
        .social-media-continer {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          max-width: 100%;
          margin-top: 18px;

          @media (max-width: 450px) {
            max-width: 99%;
          }

          .platform {
            border: 1px solid #e2e8f0;
            padding: 2px 12px;
            padding-bottom: 6px;
            border-radius: 10px;
            .text {
              font-size: 13px;
              font-weight: 300;
              margin-left: 5px;
              margin-top: 5px;
              @media (max-width: 520px) {
                font-size: 10px;
                font-weight: 200;
              }
            }
            img {
              width: 20px;
              height: 20px;
              @media (max-width: 520px) {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
      .profile-img {
        width: 140px;
        height: 150px;
        z-index: 100;
        border-radius: 35px;
        object-fit: cover;
        border: 2px solid white;
        box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.4),
          -2px 3px 8px rgba(0, 0, 0, 0.4);

        @media (max-width: 900px) {
          width: 120px;
          height: 130px;
        }

        @media (max-width: 650px) {
          width: 110px;
          height: 120px;
        }

        @media (max-width: 450px) {
          width: 100px;
          height: 110px;
        }
      }
    }
  }

  .influencer-detail-and-packages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    .content-container {
      width: 1100px;

      @media (max-width: 1250px) {
        width: 980px;
      }

      @media (max-width: 1050px) {
        width: 860px;
      }

      @media (max-width: 900px) {
        width: 740px;
      }
      @media (max-width: 770px) {
        width: 640px;
      }

      @media (max-width: 770px) {
        width: 620px;
      }

      @media (max-width: 650px) {
        width: 500px;
      }

      @media (max-width: 650px) {
        width: 500px;
      }

      @media (max-width: 520px) {
        width: 440px;
      }

      @media (max-width: 450px) {
        width: 380px;
      }

      .description {
        font-size: 15px;
        font-weight: 200;
        word-wrap: break-word;
      }
    }
  }
}
.creator-profile-view {
  .cover-img {
    border-radius: 15px;
    width: 360px;
    height: 470px;
    object-fit: cover;

    @media (max-width: 1250px) {
      width: 320px;
      height: 450px;
    }

    @media (max-width: 1050px) {
      width: 280px;
      height: 410px;
    }

    @media (max-width: 900px) {
      width: 240px;
      height: 370px;
    }
    @media (max-width: 770px) {
      width: 200px;
      height: 320px;
    }

    @media (max-width: 650px) {
      width: 160px;
      height: 270px;
    }
    @media (max-width: 520px) {
      width: 140px;
      height: 230px;
    }

    @media (max-width: 450px) {
      width: 120px;
      height: 190px;
    }
  }
}

.view-insigts-dropdown {
  position: absolute;
  top: 70px;
  right: 7px;
}
.creator-profile-action-btn {
  background-color: white !important;
  color: black !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  border: 1px solid #e2e8f0 !important;
  padding: 10px 18px !important;

  &:hover {
    background-color: white !important;
  }
  .btn-icon {
    font-size: 19px !important;
    margin-right: 5px !important;
    color: black !important;
  }

  .transform-icon {
    transform: rotate(-50deg) !important;
  }
}
.font-weight-600 {
  font-weight: 600;
}

.creator-profile-resubmit-btn {
  background-color: #94a4ff !important;
  color: white !important;
  font-size: 12px !important;
  border-radius: 25px !important;
  border: 1px solid #e2e8f0 !important;
  padding: 10px !important;

  &:hover {
    background-color: #94a4ff !important;
  }

  .transform-icon {
    transform: rotate(-50deg) !important;
  }
}
