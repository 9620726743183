.default-badge {
  background-color: #f6f7fb;
  padding: 10px;
  border-radius: 50px;
  font-size: 13px;
}
.set-default {
  color: #94a4ff;
  font-size: 13px;
  cursor: pointer;
}
.detail-text {
  color: #343434;
  font-size: 13px;
}
.cursor-pointer {
  cursor: pointer;
}
.card-bg-black {
  // background-color: #343434 !important;
  border-radius: 9px;
  padding: 8px;
}
.new-payment-text {
  color: #000000;
  font-size: 15px;
}
.payment-alert-detail-section {
  background-color: #feeeee;
  color: #f65353;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 11px;
}
.alert-icon-detail {
  width: 20px;
}