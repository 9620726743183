.imageDropzone {
  border: 2px dashed #e2e8f0;
  border-radius: 10px;
  height: 130px;
  width: 100%;
  background-color: #f6f7fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 25px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  padding: 20px;

  .icon {
    margin-bottom: 12px;
  }

  .text {
    font-size: 20px;
    font-weight: 600;
  }

  .errorMessage {
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    color: red;
    bottom: 5px;
    width: 100%;
    text-align: center;
    padding: 0 1%;
  }

  &.dragActive {
    background-color: #d1d5db;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
  }
}
