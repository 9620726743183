.connected-account-status-section {
  font-size: 14px;
  border-radius: 15px;
  padding: 15px;
}

.bgRestricted {
  background-color: #feeeee;
}

.bgEnabled {
  background-color: #cbf0f2;
}

.bgCompleted {
  background-color: #d5f5c1;
}

.bgPending {
  background-color: #a8dcd6;
}

.colorRestricted {
  font-weight: bolder;
  color: #f65353;
}

.colorEnabled {
  color: #0055bb;
}

.colorPending {
  color: #20b2aa;
}

.colorCompleted {
  color: #016908;
}

.circle {
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  background-color: #0fb957;
  border-radius: 50%; // Makes it a circle
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .tick {
    width: 6px; // Adjust size as needed
    height: 12px; // Adjust size as needed
    border: solid white; // White color for the tick
    border-width: 0 2px 2px 0; // Create a tick shape
    transform: rotate(45deg); // Rotate to form a tick
    position: absolute;
  }
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.stripe-connect-spinner {
  position: absolute;
  left: 6px;
  width: 15px !important;
  height: 15px !important;
  z-index: 10;
  top: 12px;
}
