.find-influencers-empty-view {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image-container {
    width: 250px;
    height: 250px;
    align-self: center;

    .image {
      width: 100%;
      height: 100%;
    }
  }

  .heading {
    font-weight: 700;
    font-size: 24px;
    color: black;
    margin-bottom: 1rem;
    text-align: center;
  }

  .paragraph {
    font-weight: 400;
    font-size: 20px;
    color: #a0a3bd;
    margin-bottom: 2rem;
    text-align: center;
  }
}
