.progressTracker {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-right: 100px;

  .orderStages {
    display: flex;
    gap: 12.5px;

    .orderStage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 40px;
      position: relative;
      background-color: #e2e8f0;
      color: black;
      font-size: 16px;
      font-weight: 600;

      &.orderStageActive {
        background-color: #94a4ff;
        color: white;

        &::after {
          border-left: 12.5px solid #94a4ff;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 12.5px solid white;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        right: -12px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 12.5px solid #e2e8f0;
        z-index: 1;
      }

      &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;

        &::before {
          content: none;
        }
      }

      &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;

        &::after {
          content: none;
        }
      }
    }
  }

  .skeleton {
    border-radius: 50px;
  }
}
