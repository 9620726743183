.marketplace-card-heading {
  font-size: 15px;
  font-weight: 600;
}

.marketplace-card-sub-heading {
  font-size: 14px;
  font-weight: 200;
}
.card-media-section {
  position: relative;
  height: 390px;

  .marketplace-card-text-location {
    position: absolute;
    font-size: 16px;
    font-weight: 300;
    bottom: 8px;
    left: 12px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.6);
  }
  .card-image {
    height: 390px;
    object-fit: cover;
    width: 100%;
  }
}
.text-elipsis-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
