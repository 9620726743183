.orderControls {
  .reviewForm {
    .reviewsHeading {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .reviewsFlexContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 12px;

      .review {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .description {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
