.creatorOnboardingFormPlatformsStep {
  width: 100%;

  .headerFlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .heading {
      font-size: 16px;
      font-weight: 700;
    }

    .defaultButton {
      display: none;
    }
  }

  .addAccountButton {
    border: 3px dotted #94a4ff;
    border-radius: 10px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;

    .label {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .socialAccountsFlexContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .creatorPlatformCard {
      flex-basis: calc(50% - 10px) !important;
    }
  }

  .formFieldsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .flexContainer {
      width: 100%;
      display: flex;
      gap: 10px;

      .defaultTextInput {
        flex-basis: calc(30% - 5px);
        width: 100%;
      }

      .usernameSearchbar {
        flex-basis: calc(70% - 5px);
        width: 100%;
      }
    }
  }
}

@media (max-width: 950px) {
  .headerFlexContainer {
    .defaultButton {
      display: flex !important;
    }
  }

  .addAccountButton {
    display: none !important;
  }
}

@media (max-width: 840px) {
  .socialAccountsFlexContainer {
    flex-direction: column !important;
    gap: 10px !important;
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 600px) {
  .headerFlexContainer {
    .heading {
      font-size: 14px !important;
    }
  }

  .creatorCard {
    padding: 16px 20px !important;

    .profilePicture {
      width: 90px !important;
      height: 90px !important;
    }

    .profileDetails {
      .fullname {
        font-size: 16px !important;
        margin-bottom: 0.5rem !important;
      }

      .username {
        font-size: 14px !important;
      }
    }

    .creatorCardActions {
      bottom: 16px !important;
      right: 20px !important;

      .actionIcon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
