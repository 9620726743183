.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 0;

  .pagination {
    margin-bottom: 0 !important;
    display: flex;
    gap: 12px;

    .page-item {
      .page-link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 50% !important;
        background-color: white !important;
        color: #8392ab;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        &:focus,
        &:active,
        &:hover {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }

    .page-item.active > .page-link {
      color: #55abeb !important;
      font-weight: 700;
    }
  }
}

.pagination .not-clickable {
  pointer-events: none;
  cursor: default;
}
