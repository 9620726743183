.creatorCard {
  padding: 30px;
  flex-basis: calc(50% - 10px);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
  transition: background-color 0.3s ease;
  position: relative;

  &:hover {
    background-color: #e2e8f0;
  }

  .creatorCardFlexContainer {
    display: flex;
    gap: 20px;

    .profilePicture {
      border-radius: 15px;
      overflow: hidden;
      width: 120px;
      height: 120px;
      position: relative;

      .image {
        width: 100%;
        height: 100%;
      }

      .platform {
        position: absolute;
        bottom: 0;
        right: 0;

        .icon {
          width: 30px;
          height: 30px;
        }
      }
    }

    .profileDetails {
      .fullname {
        font-size: 20px;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 1rem;
      }

      .username {
        font-size: 16px;
        font-weight: 600;
        color: #55abeb;
        text-decoration: underline;
        margin-bottom: 12px;
      }

      .followers {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .creatorCardActions {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    gap: 12px;

    .actionIcon {
      cursor: pointer;
    }
  }
}
