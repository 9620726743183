@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body:has(.pricing_plan_main) {
  background-color: #94a3ff !important;
}

.pricing_plan_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .back-button {
    left: 25px;
    top: 25px;
    position: absolute;
    cursor: pointer;
  }

  .pricing_plan_heading {
    padding: 40px 20px 0px;
    font-size: 30px;
    text-align: center;
    font-family: "Balgin-Bold";
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
  }

  .switch_section {
    font-size: 16px;
    text-align: center;
    font-family: "Balgin-Bold";
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    margin: 40px 0px 72px;

    .sales_arrow_icons {
      position: relative;

      .arrow_icon {
        margin-left: 4.8px;
      }

      .sales_icon {
        position: absolute;
        top: 15px;
      }
    }

    .month_on {
      .switch_inner {
        transform: translateX(0);

        img {
          transform: rotate(0deg);
        }
      }
    }

    .annual_on {
      .switch_inner {
        transform: translateX(100%);

        img {
          transform: rotate(180deg);
        }
      }
    }

    .switch {
      width: 64px;
      height: 36px;

      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 22px;
      opacity: 1;
      padding: 4px;

      cursor: pointer;

      transition: transform 0.1s ease;

      &:hover {
        transform: scale(1.02);
        opacity: 1;

        .switch_inner {
          background: #d3c205 0% 0% no-repeat padding-box;
        }
      }

      .switch_inner {
        display: flex;
        align-items: center;
        justify-content: center;

        transition: transform 0.3s ease;

        width: 50%;
        height: 28px;
        background: #ffea00 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 50%;

        img {
          transition: transform 0.3s ease;
        }
      }
    }
  }

  .pricing_plan_cards {
    padding: 0px 85px;
    margin: 0px 0px 82px 0px;
    display: flex;
    justify-content: center;
    gap: 20px;

    width: 100%;

    .pricing_plan_card {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 20px;
      opacity: 1;
      padding: 25px 21px 90px;
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      transition: transform 0.5s ease;

      &:hover {
        transform: translate(0px, -15px);
      }

      .most_popular {
        position: absolute;
        top: 0;
        transform: translate(0px, -50%);

        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 22px;
        opacity: 1;

        text-align: center;
        font-family: "Poppins-Medium";
        font-size: 12px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;

        height: 26px;

        padding: 6px 11px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .get_start_btn {
        position: absolute;
        bottom: 25px;
        left: 21px;
        right: 21px;
        background: #ffea00 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #0000004d;
        border-radius: 30px;
        opacity: 1;
        border: none;

        height: 36px;

        font-size: 14px;
        text-align: center;
        font-family: "Balgin-Regular";
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        opacity: 1;

        transition: transform 0.3s ease;

        &:hover {
          background: #d3c205 0% 0% no-repeat padding-box;
          transform: scale(1.02);
        }
      }

      .get_start_btn_disabled {
        position: absolute;
        bottom: 25px;
        left: 21px;
        right: 21px;
        background: #ffea00 0% 0% no-repeat padding-box;
        opacity: 0.3;
        cursor: not-allowed;
        border-radius: 30px;
        border: none;
        height: 36px;
        font-size: 14px;
        text-align: center;
        font-family: "Balgin-Regular";
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        pointer-events: none;
      }

      .plan_name {
        border: 1px solid #000000;
        border-radius: 48px;
        opacity: 1;

        width: 100%;
        height: 36px;
        font-family: "Balgin-Bold";
        font-size: 16px;

        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 10px 22.62px;

        .info_icon {
          //to be implemented
        }

        &:hover {
          opacity: 0.7;
          cursor: default;
        }
      }

      .price_value {
        margin-top: 19px;
        width: 100%;
        text-align: left;
        font-family: "Raleway-Bold";
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        opacity: 1;
        font-size: 30px;

        .per_month_text {
          font-size: 13px;
          font-family: "Poppins-Regular";
        }
      }

      .billed {
        width: 100%;
        font-size: 14px;
        text-align: left;
        font-family: "Poppins-Regular";
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        opacity: 1;

        border-bottom: 1px solid #000000;
        margin-top: 5px;
        padding-bottom: 12px;
        margin-bottom: 12px;
      }

      .offers_listing {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        text-align: left;
        opacity: 1;

        font-size: 16px;
        font-family: "Poppins-Regular";

        .tick_cross_icon {
          margin-right: 3.8px;
        }

        .exporting_offers_heading {
          font-size: 14px;
          font-family: "Poppins-Medium";
          color: #000000;
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .pricing_plan_main {
    padding: 40px 15px 82px;

    .pricing_plan_heading {
      padding: 0px;
      font-size: 26px;
    }

    .pricing_plan_cards {
      padding: 0px;
      margin: 0px;

      gap: 15px;

      .pricing_plan_card {
        padding: 25px 15px 90px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .pricing_plan_main {
    padding: 65px 15px 38.44px;

    .switch_section {
      margin: 21px 0px 30px;
    }

    .pricing_plan_cards {
      padding: 0px;
      margin: 0px;

      justify-content: unset;
      flex-direction: column;
      gap: 60px;

      width: 100%;

      .pricing_plan_card {
        width: 50%;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .pricing_plan_main {
    .switch_section {
      gap: 5px;
      margin: 21px 0px 30px;
    }

    .pricing_plan_cards {
      padding: 0px 25px;

      .pricing_plan_card {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .pricing_plan_main {
    .switch_section {
      .sales_arrow_icons {
        .arrow_icon {
          width: 30px;
        }

        .sales_icon {
          width: 60px;
        }
      }
    }
  }
}

.bold-heading-text {
  font-family: "Balgin-Bold";
  font-size: 25px;
  letter-spacing: 0px;
  color: #000000;
}

.active-plan-badge {
  font-size: 10px;
  border: 1px solid #94a3ff;
  background-color: #ffea00;
  border-radius: 20px;
  padding: 5px;
  font-weight: 400;
}
