.sidePanelContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-basis: calc(35% - 5px) !important;

  .bannerMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #e2e8f0;
    font-size: 14px;
    font-weight: 700;

    &.warningMessage {
      background-color: #ffa00033;
    }

    &.successMessage {
      background-color: #21965333;
    }

    &.failureMessage {
      background-color: #ff000033;
    }
  }

  .orderControls {
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .text {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
