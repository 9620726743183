.Playbook__floating-action-button {
  position: fixed !important;
  bottom: 16px;
  right: 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  background-color: #d53c84 !important;
  border-radius: 25px !important;
  border: none;
  width: 50px;
  height: 50px;
  box-shadow: 0px 4px 20px 0px #00000040;
  transition: background-color 0.3s ease, width 0.3s ease;

  &:hover {
    background-color: #bb3474 !important;
    width: 160px;
  }

  .Playbook__floating-action-button-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 30%;
  }

  .Playbook__floating-action-button-text {
    position: absolute;
    font-size: 14px;
    color: white;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    position: absolute;
    right: 25%;
  }

  &:hover .Playbook__floating-action-button-text {
    opacity: 1;
    visibility: visible;
    right: 20%;
  }

  &:hover .Playbook__floating-action-button-icon {
    left: 20%;
  }
}

.PlaybookModal {
  position: relative;

  .MuiPaper-root {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    padding: 3rem;
    padding-bottom: 0;
    background-color: #f5f6fa !important;
  }

  .PlaybookModal__header {
    position: relative;

    .PlaybookModal__header-close-btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      top: -30px;
      right: -30px;
      background-color: white;
      border-radius: 50%;
    }

    .PlaybookModal__header-back-btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      top: -30px;
      left: -30px;
      background-color: white;
      border-radius: 50%;
    }

    .PlaybookModal__header-btn-image {
      margin-bottom: 10px;
    }

    .PlaybookModal__header-title {
      display: flex;
      justify-content: center;
      gap: 6px;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 2rem;

      .PlaybookModal__header-title-img {
        width: 25px;
        height: 25px;
      }

      .PlaybookModal__header-title-username {
        font-weight: 700;
      }
    }
  }

  .PlaybookModal__plays-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    .PlaybookModal__plays-cards--row {
      display: flex;
      gap: 1rem;

      .PlaybookModal__plays-card {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0px 4px 10px 0px #0000000a;
        cursor: pointer;
        flex: 0 50%;
        gap: 1rem;

        .PlaybookModal__plays-card-image {
          margin-bottom: 1rem;
        }

        .PlaybookModal__plays-card-title {
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 0.5rem;
        }

        .PlaybookModal__plays-card-description {
          text-align: center;
          font-weight: 400;
          font-size: 16px;
        }

        .PlaybookModal__plays-card-image-object {
          width: 100%;
          height: 100%;
        }
      }

      .PlaybookModal__plays-cards--column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 0 50%;
      }

      .PlaybookModal__plays-card--row {
        flex-direction: row;
        padding: 1rem 2rem;

        .PlaybookModal__plays-card-image {
          margin-bottom: 0 !important;
        }

        .PlaybookModal__plays-card-title {
          text-align: left !important;
        }

        .PlaybookModal__plays-card-description {
          text-align: left !important;
        }
      }

      .PlaybookModal__plays-card--column {
        padding: 2rem 7rem 3rem 7rem;
        flex-direction: column;
      }
    }
  }

  .PlaybookModal__tabs-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PlaybookModal__tab-list {
      border-radius: 25px;
      background-color: white;
      padding: 0.5rem 1rem;
      width: max-content;

      .PlaybookModal__tab-switch {
        padding: 0 1.5rem;
        height: 35px;
        min-height: 35px;
        border-radius: 25px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none !important;
      }

      .Mui-selected {
        color: white;
        background-color: black;
        border-radius: 50px;
      }

      .MuiTabs-indicator {
        display: none !important;
        background-color: black;
        border-radius: 10px 10px 0px 0px;
      }
    }
  }

  .MuiDialogTitle-root,
  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogTitle-root {
    margin-bottom: 1rem;
  }

  .PlaybookModal__tab-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 2rem !important;
  }

  .PlaybookModal__tab-panel.PlaybookModal__tab-panel-single-item {
    justify-content: center;
  }

  .PlayCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 5rem 3rem 3rem 3rem;
    flex: 0 49.3%;
    border-radius: 25px;

    .PlayCard__image-container {
      width: 105px;
      height: 105px;
      margin-bottom: 24px;
      position: relative;

      .PlayCard__image {
        width: 100%;
        height: 100%;
      }

      .PlayCard__image-overlay {
        position: absolute;
        top: -30%;
        right: -165%;
      }
    }

    .PlayCard__title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .PlayCard__description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
      padding: 0 2rem;
      text-align: center;
    }

    .PlayCard__platforms-parent-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      gap: 8px;

      .PlayCard__platforms-container {
        display: inline-flex;
        align-items: center;
        padding: 8px 12px;
        background-color: white;
        border-radius: 25px;
        gap: 8px;
        border: 1px solid #f5f6fa;

        .PlayCard__platform-switch {
          cursor: pointer;
          background: #f5f6fa;
          border-radius: 25px;
          width: 60px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;

          .PlayCard__platform-icon {
            width: 20px;
            height: 20px;
          }
        }

        .PlayCard__platform-switch--active {
          background-color: white;
          box-shadow: 0px 0px 4px 0px #00000033;
        }
      }
    }

    .PlayCard__filters-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      gap: 0.5rem;
      width: 450px;

      .filter-container,
      .text-filter-container {
        width: 100% !important;

        .react-select__control {
          border-radius: 20px !important;
          border: 1px solid #e2e8f0 !important;
          background-color: white !important;
          padding: 0px 6px;
        }

        .react-select__menu-list {
          max-height: 150px !important;
        }

        .text-filter {
          border-radius: 20px !important;
          border: 1px solid #e2e8f0 !important;
          background-color: white !important;
          padding: 1rem !important;
          height: 38px !important;
        }
      }
    }
  }

  .PlaybookModal__scroll-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    bottom: 40px;
    left: 48.7%;
    width: 36px;
    height: 36px;
    background-color: black;
    border-radius: 25px;
    border: none;
    cursor: pointer;

    img {
      width: 36px;
      height: 36px;
    }
  }
}

@media only screen and (max-width: 1350px) {
  .PlaybookModal {
    .PlayCard {
      flex: 1 0 40%;
      padding: 3rem 1rem 1rem 1rem;
    }
  }
}
