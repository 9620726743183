.optionCard {
  padding: 20px;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  .topContainer {
    display: flex;
    align-items: center;
    gap: 12px;

    .iconContainer {
      width: 30px;
      height: 30px;

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }

    .controlButton {
      margin-left: auto;
    }
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    color: black;
    max-width: 90%;
  }
}

.optionCardActive {
  border: 1px solid #94a4ff;
  background: #94a4ff33;
}
