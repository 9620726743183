.influencer-card {
  padding: 30px 14px 14px 14px;
  border-radius: 10px;
  border: none !important;
  background-color: white;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);
  width: 450px;
  height: auto;
  position: relative;

  &.influencer-direct-match {
    background: #eaedff !important;
    box-shadow: 0px 4px 3px 0px rgba(148, 164, 255, 0.5) !important;
  }

  &:hover {
    .checkbox-container {
      .MuiCheckbox-root {
        opacity: 1;
      }
    }
  }

  .card-body {
    padding: 0 !important;
  }

  .flex-container {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .image-container {
      position: relative;
      width: 150px;
      height: 150px;
      overflow: hidden;

      .image {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }

      .profile-button {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .fullname {
        color: black;
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }

      .username {
        color: #55abeb;
        font-size: 14px;
        font-weight: 600;
        text-decoration-line: underline;
        text-transform: lowercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }

      .MuiButton-root {
        width: 140px;

        .MuiButton-startIcon {
          margin-right: 5px !important;
        }
      }
    }
  }

  .metrics-container {
    display: flex;
    justify-content: center;

    .metrics {
      text-align: center;
      flex: 1;
      border-radius: 10px;
      border: 1px solid #f2f2f2;
      padding: 10px 0;
      margin: 0.2rem 0;

      .number {
        color: #152a4a;
        font-size: 14px;
        font-weight: 700;
      }

      .text {
        color: #496a9b;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .boards-container {
    display: flex;
    gap: 8px;

    .icon-container {
      position: inherit;
      width: 30px;
      height: auto;

      .icon {
        width: 100%;
        height: auto;
      }
    }
  }

  .boards {
    display: flex;
    align-items: end;
    gap: 8px;
    width: 207px;
    overflow-y: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent !important;
      opacity: 0 !important;
    }

    .board {
      display: inline-block;
      text-align: center;
      text-align: center;
      padding: 3px 12px;
      border-radius: 12px;
      background: rgba(148, 164, 255, 0.2);
      color: #133261;
      font-size: 12px;
      font-weight: 400;
      text-wrap: nowrap;
    }
  }

  .checkbox-container {
    position: absolute;
    top: 0;
    left: 5px;

    .MuiCheckbox-root {
      opacity: 0;
    }
  }

  .notes-icon {
    position: absolute;
    top: 70px;
    right: 20px;

    .icon-container {
      width: 25px;
      height: auto;
      overflow: hidden;

      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bookmark-icon {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;

    .icon-container {
      width: 25px;
      height: auto;
      overflow: hidden;

      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dropdown-container {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
  }
}

@media (max-width: 460px) {
  .influencer-card {
    margin: 10px 10px 0 10px !important;
    width: 390px !important;

    .image-container {
      width: 120px !important;
      height: 120px !important;
    }

    .socials-container {
      margin-bottom: 0 !important;
    }

    .metrics {
      padding: 5px 0 !important;
    }

    .boards {
      width: 202px;
    }
  }
}

@media (max-width: 415px) {
  .influencer-card {
    width: 355px !important;

    .boards {
      width: 157px;
    }
  }
}

.influencer-card-tooltip {
  font-family: "Open-Sans" !important;
  background-color: black !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
  padding: 10px !important;

  span::before {
    background-color: black !important;
  }
}
