.brandOrderDetails {
  .tabContent {
    .tab {
      display: none;

      &.tabActive {
        display: block;
      }
    }
  }
}
