@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

.MuiTypography-root {
  font-family: "Open-Sans" !important;
}

.mui-button {
  font-family: "Open-Sans" !important;
  border-radius: 50px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  min-width: unset !important;
  box-shadow: none !important;

  .MuiLoadingButton-loadingIndicator {
    position: unset !important;
    color: white !important;
  }

  .MuiButton-startIcon {
    margin: 0 !important;
  }
}

.mui-form-field {
  font-family: "Open-Sans" !important;

  .MuiInputBase-root {
    border-radius: 25px !important;
  }

  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 25px !important;
    border: 1px solid #e2e8f0;
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid black !important;
    }
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid red !important;
    }
  }
}

.mui-dropdown-button {
  font-family: "Open-Sans" !important;
  font-weight: 400 !important;
  text-transform: none !important;
  color: black !important;
  padding: 6px 12px !important;
  box-shadow: none !important;
}

.mui-dropdown-menu {
  .MuiPaper-root {
    .react-select__menu {
      position: relative !important;
    }
  }
}

.MuiPaper-root {
  border-radius: 10px !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06) !important;
}

.mui-icon-dropdown-button {
  min-width: unset !important;
  padding: 0 !important;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
}

.mui-dropdown-menu-item-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .dropdown-item-text {
    font-size: 16px;
    font-weight: 400;
    align-self: end;
  }

  .dropdown-item-icon {
    width: 16px;
    height: 16px;
  }

  .button-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 0.5rem;
  }
}

.mui-dropdown-menu-item-container-large {
  gap: 5rem !important;
}
