/* App.css */
.editor-container {
  width: 100%;

  .editor-class {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;

    height: 285px;
    overflow: auto;
    padding: 10px;
    border: 1px solid #f1f1f1;
    order: 1; /* Editor appears at the top */
  }
  .rdw-dropdown-selectedtext {
    color: black !important;
    text-decoration: none;
  }
  /* App.css */
  .wrapper-class {
    display: flex;
    flex-direction: column-reverse; /* Place toolbar below the editor */
  }

  .toolbar-class {
    order: 0; /* Toolbar appears at the bottom */
    border: 1px solid #f1f1f1;
    background-color: #f9f9f9;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .rdw-editor-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
  }

  .rdw-dropdown-wrapper {
    position: relative;
  }

  .rdw-link-modal {
    position: absolute;
    top: -245px;
    left: -185px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 228px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
  }

  .rdw-image-modal {
    position: absolute;
    top: -288px;
    left: -290px;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 310px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    z-index: 10060000;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
  }

  .rdw-image-modal-upload-option-label {
    padding: 5px;
    img {
      height: 60px;
      width: 60px;
    }
  }
  .rdw-dropdown-optionwrapper {
    position: absolute !important;
    top: -255px !important;
  }
  .rdw-fontsize-dropdown {
    position: relative;
  }

  .rdw-option-disabled {
    display: none;
  }

  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }
  .DraftEditor-root {
    color: #4f4f4f !important;
    font-size: 14px;
    font-weight: 200;
  }
}
