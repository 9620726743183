.influencer-insights-modal {
  .users-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 1rem;

    .heading {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 14px;
      color: black;
      font-weight: 600;
    }

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f6fa;
      border-radius: 10px;
      padding: 8px 16px;

      .profile-container {
        display: flex;
        align-items: center;
        gap: 12px;

        .image-container {
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          color: #55abeb;
          font-size: 14px;
          font-weight: 600;
          text-decoration-line: underline;
        }
      }

      .audience-metrics-container {
        display: flex;
        justify-content: center;
        gap: 12px;

        .metric {
          width: 150px;
          text-align: center;
          background-color: white;
          border-radius: 10px;
          border: 1px solid #f2f2f2;
          padding: 4px 0px;

          .number {
            color: #152a4a;
            font-size: 12px;
            font-weight: 700;
          }

          .text {
            color: #496a9b;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .tab-loading-container {
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 20px;

    .user-skeleton-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .user-skeleton {
        width: 100% !important;
      }
    }
  }
}
