.upgrade-popup-tooltip {
  font-family: "Open-Sans" !important;
  background-color: transparent !important;
  border-radius: none !important;
  padding: none !important;
  margin-bottom: 5px !important;

  .upgrade-popup-tooltip::before {
    background-color: white !important;
  }
}

div:has(> .boards-limit-upgrade-popup) {
  background-color: transparent !important;
  border-radius: none !important;
  padding: 0 !important;

  &:before {
    background-color: white !important;
  }
}

.boards-limit-upgrade-popup {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;

  .text {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: black;
  }
}
