.brandOrderMessages {
  .uploadedFilesContainer {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    gap: 4px;
    border-radius: 10px 10px 0 0;
    border: 1px solid #e2e8f0;
    border-bottom: none;
    padding: 13px 20px;

    .attachment {
      border-radius: 10px;
      width: 75px;
      height: 75px;
    }

    .fileUploadPlaceholder {
      gap: 4px;
      flex-basis: unset !important;
      width: 75px;
      height: 75px;

      .iconsContainer {
        gap: 4px;

        .icon {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
