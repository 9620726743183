.brandOrderRequirements {
  border: 1px solid #e2e8f0;
  border-radius: 10px;

  .packageInfoContainer {
    background-color: #f5f6fa;
    padding: 24px;

    .flexContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      .typeIconContainer {
        width: 30px;
        height: 30px;
        overflow: hidden;

        .icon {
          width: 100%;
          height: 100%;
        }
      }

      .packageName,
      .packagePrice {
        font-size: 16px;
        font-weight: 700;
      }

      .packagePrice {
        margin-left: auto;
      }
    }

    .packageDescription {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .requirementPoints {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    gap: 25px 50px;
    border-bottom: 1px solid #e2e8f0;

    .point {
      display: flex;
      gap: 20px;
      flex-basis: calc(50% - 25px) !important;

      .icon {
        width: 24px;
        height: 24px;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .buttonContainer {
    padding: 12px 16px;
  }

  .requirementsContainer {
    .subHeading {
      font-size: 16px;
      font-weight: 700;
      margin: 10px 24px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      margin: 10px 24px;
    }

    .questionsContainer {
      display: flex;
      flex-direction: column;

      .question {
        border-top: 1px solid #e2e8f0;
        padding: 12px 24px;

        &:last-child {
          border-bottom: 1px solid #e2e8f0;
        }
      }
    }

    .dosAndDontsContainer {
      display: flex;
      gap: 12px;
      margin: 0px 24px 24px 24px;

      .column {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 12px;
        border-radius: 10px;
        flex-basis: calc(50% - 6px) !important;
      }

      .doContainer {
        background-color: #6fcf9750;
      }

      .dontContainer {
        background-color: #eb575750;
      }

      .doItem,
      .dontItem {
        position: relative;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #343434;

        &::before {
          content: "•";
          position: absolute;
          left: 0;
          top: -4px;
          color: #00000050;
          font-size: 16px;
        }
      }
    }
  }
}
