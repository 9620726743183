.pointCard {
  .pointCardFlexContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 50px;
    padding: 10px 25px;
    margin-bottom: 3px;

    .input {
      border: none;
      outline: none;
      width: 100%;
    }

    .characterCount {
      font-size: 14px;
      font-weight: 600;
      color: black;
    }

    .characterCountExceeded {
      color: red;
    }
  }

  .errorMessage {
    color: rgb(211, 47, 47);
    font-weight: 400;
    font-size: 0.75rem;
    margin: 2px 0;
  }
}
