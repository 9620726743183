.orderRevisionModal {
  .MuiPaper-root {
    max-height: unset !important;
    max-width: unset !important;
  }

  .modalContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    padding: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .heading {
        font-size: 18px;
        font-weight: 700;
      }

      .closeButton {
        cursor: pointer;
        width: 30px;
        height: 30px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .paragraph {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
    }

    .defaultTextInput {
      margin-bottom: 24px;
    }
  }
}
