.selected-filters-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 1rem;

  .heading {
    font-size: 12px;
    font-weight: 600;
    color: black;
  }

  .selected-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background-color: white;
    border: none;
    border-radius: 50px;

    .text {
      display: flex;
      gap: 3px;

      .key {
        font-size: 12px;
        font-weight: 600;
        color: black;
        text-transform: capitalize;
      }

      .value {
        font-size: 12px;
        font-weight: 400;
        color: black;
      }
    }

    .icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .selected-playbook {
    padding: 6px 12px;
    background-color: #94a4ff;
    border: none;
    border-radius: 50px;

    .text {
      font-size: 12px;
      font-weight: 600;
      color: white;
    }
  }
}
