.brandOrderMessages {
  .messageInput {
    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px;
      border: 1px solid #e2e8f0 !important;
    }

    .MuiInputBase-input {
      padding: 13px 20px !important;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .MuiInputBase-multiline {
      padding: 0 !important;
    }

    .MuiInputAdornment-root {
      margin-right: 10px !important;
    }

    .icon {
      width: 30px;
      height: 30px;
    }
  }
}
