.find-influencers-no-filters-view {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .heading {
    font-weight: 700;
    font-size: 24px;
    color: black;
    margin-bottom: 1rem;
    text-align: center;
  }

  .paragraph {
    font-weight: 400;
    font-size: 20px;
    color: #a0a3bd;
    margin-bottom: 2rem;
    text-align: center;
  }

  .MuiButton-root {
    align-self: center;
  }
}
