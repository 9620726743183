.generic-modal-container {
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 1.5rem;

  .icon-container {
    text-align: center;
    height: 75px;
    margin-bottom: 2rem;
  }

  .heading,
  .text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .text {
    text-align: center;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .form-field-container {
    width: 100%;
    margin-bottom: 2rem;
  }
}
