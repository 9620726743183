.provider-card {
  width: 16rem !important;
  border-radius: 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.font-13 {
  font-size: 13px;
}

.disconnect-spinner-position {
  position: absolute;
  z-index: 100;
  top: 9px;
  right: 109px;
}
