.text-search-section {
  width: 100%;

  .searchbar-container {
    position: relative;
    width: 100%;
    margin-top: 10px;

    .searchbar {
      width: 100%;
      padding: 12px 20px 13px 40px;
      border-radius: 50px;
      border: 0.5px solid #e2e8f0;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 300;
      &:focus,
      &:active,
      &:hover {
        outline: none;
      }
    }

    .search-icon-container {
      width: 18px;
      height: 18px;
      object-fit: cover;
      position: absolute;
      top: 9px;
      left: 17px;

      .search-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}
