.creatorPackageCard {
  min-height: 150px;
  border: 1px solid #e2e8f0;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: fit-content;
  word-wrap: break-word;
  white-space: normal;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .flexContainer {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .description {
    font-size: 14px !important;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .title,
  .price {
    font-size: 15px;
    font-weight: 700;
  }

  .actionsFlexContainer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 6px;

    .actionIcon {
      width: 35px;
      height: 32px;
      border: 1px solid #f2f2f2;
      border-radius: 50px;
      padding: 8px;
      cursor: pointer;
    }
  }

  .viewMoreLink {
    cursor: pointer;
    color: #94a3ff;
  }
}
