.Tooltip__default {
  font-family: "Open-Sans" !important;
  background-color: black !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
  padding: 10px !important;
  max-width: 200px !important;
  text-align: center !important;

  span::before {
    background-color: black !important;
  }
}
