.platform-switch-container {
  display: flex;
  padding: 8px 12px;
  background-color: #f5f6fa;
  border-radius: 50px;
  gap: 8px;

  .platform-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px 20px;
    background-color: inherit;
    border: none !important;
    border-radius: 50px;
    min-width: 135px;
    height: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    .name {
      color: #555f61;
      font-size: 14px;
      font-weight: 400;
    }

    .icon-container {
      width: 24px;
      height: 24px;

      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .active {
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
