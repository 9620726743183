.highlight-section {
  border: 1px solid #94a4ff;
  border-radius: 13px;
  background-color: #94a4ff33;
}
.active-plan-detail {
  padding: 20px;
  width: 300px;
  display: inline-block;
}
.price-text {
  font-size: 40px;
}
.renew-date-text {
  font-size: 13px;
  font-weight: 400;
}
.cancel-btn {
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  padding: 12px 20px;
  background-color: #ffff;
}
.w-650 {
  width: 650px !important;
}
.credits-text {
  color: #94a4ff;
  font-size: 10px;
}
.position-relative {
  position: relative;
}
.pending-payment-icon {
  position: absolute;
  right: 45%;
  width: 60px;
  top: 6px;
}
.trigger-payment-btn {
  border: 1px solid black !important;
  color: black;
  font-size: 14px;
  border-radius: 50px;
  background-color: #ffff;
  padding: 10px 16px;
}
.view-plans-button {
  border: 1px solid #ffea00 !important;
  color: black;
  font-size: 15px;
  border-radius: 50px;
  background-color: #ffea00;
  padding: 10px 16px;
}
