@font-face {
  font-family: "Open-Sans";
  src: url("../../../assets/fonts/Open_Sans/static/OpenSans-Regular.ttf") format("opentype");
}

body {
  background-color: #f5f6fa !important;
}

.boards-page {
  font-family: "Open-Sans" !important;

  .boards-select-container {
    margin: 30px 24px 0 24px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .actions-bar {
    margin: 30px 24px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.04);

    .searchbar-container {
      flex: 1;
      position: relative;
      max-width: 450px;

      .searchbar {
        width: 100%;
        height: auto;
        padding: 16px 16px 16px 48px;
        border-radius: 25px;
        border: 1px solid #e2e8f0;
        background-color: white;

        &:focus,
        &:active,
        &:hover {
          outline: none;
        }
      }

      .search-icon-container {
        position: absolute;
        top: 25%;
        left: 16px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }

      .loader-container {
        position: absolute;
        top: 25%;
        right: 16px;
      }
    }
  }

  .react-board-container {
    margin: 1rem;

    .react-kanban-board {
      gap: 2rem !important;
      align-items: stretch !important;
    }

    .react-kanban-column {
      min-height: 60px !important;
      width: 330px !important;
      border-radius: 7.5px;
      padding: 20px 10px 8px 20px !important;
      background-color: white !important;
      overflow: hidden;

      &:nth-child(4n + 1) {
        border-top: 12px solid #94a4ff;
      }

      &:nth-child(4n + 2) {
        border-top: 12px solid #32b3f8;
      }

      &:nth-child(4n + 3) {
        border-top: 12px solid #ffc500;
      }

      &:nth-child(4n + 4) {
        border-top: 12px solid #5fce57;
      }

      > div {
        &:nth-child(2) {
          max-height: 750px !important;
          overflow-x: hidden;
        }
      }
    }
  }

  .no-boards-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 5rem;

    .heading {
      font-size: 45px;
      font-weight: 700;
    }

    .image-container {
      overflow: hidden;
      margin-bottom: 2rem;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .description {
      font-size: 20px;
      font-weight: 600;
      color: #a0a3bd;
      margin-bottom: 3rem;
    }
  }
}
