.creatorOnboardingFormCategoriesStep {
  width: 100%;

  .categoriesSelectContainer {
    .heading {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 31px;
    }

    .searchBar {
      margin-bottom: 26px;
    }

    .categoryCardsContainer {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      margin-right: -12px;

      .imageCard,
      .categorySkeleton {
        flex-basis: calc(16.66% - 12px);
        max-width: calc(16.66% - 12px);
        flex-grow: 1 !important;
        flex-shrink: 1 !important;
      }
    }
  }
}

@media (max-width: 1230px) {
  .categoriesSelectContainer {
    .categoryCardsContainer {
      .imageCard,
      .categorySkeleton {
        flex-basis: calc(20% - 12px) !important;
        max-width: calc(20% - 12px) !important;
      }
    }
  }
}

@media (max-width: 1180px) {
  .categoriesSelectContainer {
    .categoryCardsContainer {
      .imageCard,
      .categorySkeleton {
        .label {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .categoriesSelectContainer {
    .searchBar {
      display: none;
    }
  }
}

@media (max-width: 800px) {
  .categoriesSelectContainer {
    .categoryCardsContainer {
      .imageCard,
      .categorySkeleton {
        flex-basis: calc(25% - 12px) !important;
        max-width: calc(25% - 12px) !important;
      }
    }
  }
}

@media (max-width: 650px) {
  .categoriesSelectContainer {
    .categoryCardsContainer {
      .imageCard,
      .categorySkeleton {
        flex-basis: calc(33% - 12px) !important;
        max-width: calc(33% - 12px) !important;

        .label {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .categoriesSelectContainer {
    .heading {
      font-size: 14px !important;
      margin-bottom: 16px !important;
    }
  }
}

@media (max-width: 480px) {
  .categoriesSelectContainer {
    .categoryCardsContainer {
      .imageCard,
      .categorySkeleton {
        height: 160px !important;

        .label {
          font-size: 12px !important;
        }
      }
    }
  }
}
