/* src/components/CardSetupForm.css */

.card-setup-form {
  width: 100%;
  margin: auto;
}

.card-setup-label {
  display: block;
  margin-bottom: 10px;
}

.card-setup-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.card-setup-element {
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
}

.card-setup-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card-setup-button:hover {
  background-color: #45a049;
}

.submit-button {
  border-radius: 25px;
  margin-top: 20px;
  background-color: #000 !important;
  color: white !important;
  padding: 12px 25px;
  border: none;
  font-size: 13px;
}

.submit-button-disabled {
  border-radius: 25px;
  margin-top: 20px;
  background-color: #000 !important;
  color: white !important;
  padding: 12px 25px;
  border: none;
  font-size: 13px;
  opacity: 0.4;
  cursor: progress;
}

.stripe-payment-modal {
  .MuiBox-root {
    overflow: auto;
    max-height: calc(100vh - 40px);
  }
}
